import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';


function SubTab({ panes }) {
  return (
    <Tab
      menu={{ secondary: true }}
      panes={panes}
      className="subtab"
      renderActiveOnly={false}
    />
  );
}

SubTab.propTypes = {
  panes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SubTab;

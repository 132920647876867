import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({ isAuthenticated, component: Component, tab, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated
        ? <Component tab={tab} {...props} />
        : (
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }} />
        )
    )}
  />
);

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object
  ]).isRequired,
  tab: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.string).isRequired
};

export default connect(
  store => ({
    isAuthenticated: !!localStorage.getItem('iosEmail') // store.user.email // localStorage.getItem('iosEmail')
  })
)(ProtectedRoute);

import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function MonthFilter(props) {
  const monthOptions = props.monthData.data.map((month, i) => ({ text: month, value: i + 1 }));
  return (
    <>
      <span id="date-label">Date</span>
      <Dropdown
        options={monthOptions}
        fluid
        selection
        aria-labelledby="date-label"
        placeholder="Select a month"
        onChange={(e, { value }) => props.onChangeMonth(value)}
        value={props.monthData.selectedMonth}
      />
    </>
  );
}

MonthFilter.propTypes = {
  monthData: PropTypes.shape({
    data: PropTypes.array,
    selectedMonth: PropTypes.number
  }).isRequired,
  onChangeMonth: PropTypes.func.isRequired
};

export default MonthFilter;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Dropdown, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import omgLogo from '../../assets/omg_logo.png';
import './OMGNavigation.css';
import { logout } from '../../actions/authActions';

function OMGNavigation(props) {
  const location = useLocation();
  return (
    <div id="OMGNavigation">
      <Menu inverted className="omni-nav">
        <Menu.Item className="logo">
          <Image size="tiny" src={omgLogo} alt="OMG Logo" />
        </Menu.Item>

        <Menu.Menu position="right">
          <Dropdown aria-label="User email address" item text={props.userEmail}>
            <Dropdown.Menu>
              {location.pathname !== '/' && (
                <Dropdown.Item aria-label="Homepage" as={Link} to="/">
                  Home
                </Dropdown.Item>
              )}

              {location.pathname !== '/forecast-upload' && (
                <Dropdown.Item aria-label="Forecast Upload" as={Link} to="/forecast-upload">
                  Forecast Upload
                </Dropdown.Item>
              )}

              {props.userEmail.indexOf('annalect.com') !== -1
                && location.pathname !== '/forecast-match-actuals' && (
                <Dropdown.Item aria-label="Forecast Match Actuals" as={Link} to="/forecast-match-actuals">
                  Forecast Match Actuals
                </Dropdown.Item>
              )}

              {['Region Admin', 'Agency Head'].includes(props.userRole)
                && location.pathname !== '/forecast-match-actuals' && (
                <Dropdown.Item aria-label="Admin" as={Link} to="/admin">
                  Admin
                </Dropdown.Item>
              )}
              <Dropdown.Item
                aria-label="Log out"
                onClick={() => props.logout()}
              >
                Log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

      <div className="sub-omni-nav">
        <span className="app-name">Investment Operating System</span>
        {/* <span className="other-links">Admin</span> */}
      </div>
    </div>
  );
}

OMGNavigation.propTypes = {
  userEmail: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired
};

export default connect(
  // state => ({
  //   test: state.test
  // }),
  null,
  { logout }
)(OMGNavigation);

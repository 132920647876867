import React from 'react';
import { Form, Button, Message, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class LoginForm extends React.Component {
  state = {
    data: {
      username: '',
      password: ''
    },
    loading: false,
    errors: {}
  };

  onChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  onSubmit = e => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props.submit(this.state.data).catch(err => {
        let errorMessage = "Error Connecting to Login Service";
        if (_.has(err, "response.data.non_field_errors")) {
          errorMessage = err.response.data.non_field_errors[0];
        }
        this.setState({
          errors: { global: errorMessage },
          loading: false
        });
      });
    }
  };

  validate = data => {
    const errors = {};
    if (!data.username) errors.username = "Username cannot be empty";
    if (!data.password) errors.password = "Password cannot be blank";
    if (data.username.includes('@')) errors.username = "Username cannot contain '@' domain part";

    return errors;
  };

  render() {
    const { data, errors, loading } = this.state;
    return (
      <Form size="large" onSubmit={this.onSubmit} loading={loading}>
        <Segment>
            {errors.global && (
                <Message negative>
                    <Message.Header>
                        Error Authenticating
                    </Message.Header>
                    <p>{errors.global}</p>
                </Message>
            )}

            <Form.Field>
                <Form.Input
                    style={{ marginBottom: 0 }}
                    icon="user"
                    iconPosition="left"
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    defaultValue={data.username}
                    onChange={this.onChange}
                />
                {errors.username && (
                    <Message errors content={errors.username} negative/>
                )}
            </Form.Field>

            <Form.Field>
                <Form.Input
                    style={{ marginBottom: '0 !important' }}
                    icon="lock"
                    iconPosition="left"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    defaultValue={data.password}
                    onChange={this.onChange}
                />

                {errors.password && (
                   <Message errors content={errors.password} negative/>
                )}
            </Form.Field>
            <Button id="button" primary  size="large">
                Login
            </Button>
        </Segment>
      </Form>
    );
  }
}

LoginForm.protoTypes = {
    submit: PropTypes.func.isRequired
};

export default LoginForm;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid, Header, Button, Dropdown, Message, Input, Divider, Icon, Loader, Dimmer
} from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import ConfigAPI from '../../api/ConfigTablesAPI';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import OMGBreadcrumb from '../OMGBreadcrumb/OMGBreadcrumb';
import isUserAdmin from '../../lib/isAdminUser';
import { setActiveIndex } from '../../actions/adminPageAction';

export class IosBuyerAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iosBuyer: '',
      clientOptions: [],
      clientsLoading: true,
      selectedClient: null,
      selectedClientGroup: null,
      clientGroupOptions: [],
      clientGroupLoading: true,
      apiError: false,
      apiSuccess: false,
      buyerLevels: [
        { key: 'CLIENT_GROUP_LEVEL', value: 'CLIENT_GROUP_LEVEL', text: 'CLIENT_GROUP_LEVEL' },
        { key: 'CLIENT_LEVEL', value: 'CLIENT_LEVEL', text: 'CLIENT_LEVEL' }
      ],
      selectedLevel: null,
      creatingIosBuyer: false,
      errorMessage: '',
      fetching: false
    };
    this.buyerLevelSelected = this.buyerLevelSelected.bind(this);
    this.addUpdateIosBuyer = this.addUpdateIosBuyer.bind(this);
    this.clientGroupSelected = this.clientGroupSelected.bind(this);
    this.clientSelected = this.clientSelected.bind(this);
    this.buyerEntered = this.buyerEntered.bind(this);
    this.redirectToBack = this.redirectToBack.bind(this);
  }

  componentDidMount() {
    const { buyerId } = this.props.match.params;
    const { agencyId } = this.props.match.params;
    if (buyerId && agencyId && isUserAdmin(localStorage.iosRole)) {
      this.fetchAdvertiserDetails(agencyId, buyerId);
    }
    if (!isUserAdmin(localStorage.iosRole)) {
      this.props.history.push('/unauthorized');
    }
    if (this.props.selectedAgency) {
      this.fetchClientGroups();
      this.fetchClients();
    }
  }

  componentDidUpdate(previous) {
    if (previous.selectedAgency !== this.props.selectedAgency) {
      this.fetchClientGroups();
      this.fetchClients();
    }
  }

  async fetchClientGroups() {
    try {
      this.setState({ clientGroupLoading: true });
      let clientGroupOptions = await ConfigAPI.getAgencyClientGroups(this.props.selectedAgency);
      clientGroupOptions = clientGroupOptions.map(ag => ({
        key: ag.id,
        text: ag.name,
        value: ag.id
      }));
      clientGroupOptions.unshift({ key: 'empty', text: 'Select', value: null });
      this.setState({ clientGroupLoading: false, clientGroupOptions });
    } catch (error) {
      this.setState({ clientGroupLoading: false, errorMessage: 'Error fetching client groups' });
    }
  }

  async fetchClients() {
    try {
      this.setState({ clientsLoading: true });
      let clientOptions = await ConfigAPI.getAgencyClients(this.props.selectedAgency);
      clientOptions = clientOptions.map(ag => ({
        key: ag.id,
        text: ag.name,
        value: ag.id
      }));
      clientOptions.unshift({ key: 'empty', text: 'Select', value: null });
      this.setState({ clientsLoading: false, clientOptions });
    } catch (error) {
      this.setState({ clientsLoading: false, errorMessage: 'Error fetching clients' });
    }
  }

  async fetchAdvertiserDetails(agencyId, buyerId) {
    try {
      this.setState({ fetching: true });
      const buyer = await ConfigAPI.getIosBuyerDetails(agencyId, buyerId);
      this.setState({
        fetching: false,
        iosBuyer: buyer.name,
        selectedLevel: buyer.level,
        selectedClient: buyer.client_id,
        selectedClientGroup: buyer.client_group_id
      });
    } catch (error) {
      this.setState({ apiError: true, errorMessage: 'User Not found', fetching: false });
    }
  }

  buyerEntered(e, { value }) {
    this.setState({ iosBuyer: value });
  }

  clientSelected(e, { value }) {
    return this.setState({ selectedClient: value });
  }

  clientGroupSelected(e, { value }) {
    return this.setState({ selectedClientGroup: value });
  }

  buyerLevelSelected(e, { value }) {
    return this.setState({ selectedLevel: value });
  }

  async addUpdateIosBuyer() {
    this.setState({ apiError: false, apiSuccess: false });
    const { buyerId } = this.props.match.params;
    const iosBuyer = {
      buyer_name: this.state.iosBuyer,
      client_group: this.state.selectedClientGroup,
      client: this.state.selectedClient,
      level: this.state.selectedLevel,
      agency: this.props.selectedAgency
    };

    if (!iosBuyer.buyer_name) {
      this.setState({ apiError: true, errorMessage: 'IOS Advertiser name cannot be null' });
      return false;
    }
    if (!iosBuyer.agency) {
      this.setState({ apiError: true, errorMessage: 'IOS Advertiser agency cannot be null' });
      return false;
    }
    if (!iosBuyer.client_group && !iosBuyer.client) {
      this.setState({ apiError: true, errorMessage: 'IOS Advertiser should either have a client or a client group' });
      return false;
    }
    if (!iosBuyer.level) {
      this.setState({ apiError: true, errorMessage: 'IOS Advertiser level cannot be null' });
      return false;
    }
    if (iosBuyer.level === 'CLIENT_GROUP_LEVEL' && !iosBuyer.client_group) {
      this.setState({ apiError: true, errorMessage: 'IOS Advertiser level is set to Client Group but the client group is empty' });
      return false;
    }

    if (iosBuyer.level === 'CLIENT_LEVEL' && !iosBuyer.client) {
      this.setState({ apiError: true, errorMessage: 'IOS Advertiser level is set to Client but the client is empty' });
      return false;
    }

    if (iosBuyer.client && iosBuyer.client_group) {
      this.setState({ apiError: true, errorMessage: 'Please select either client or a client group, not both' });
      return false;
    }

    try {
      this.setState({ creatingIosBuyer: true });
      // eslint-disable-next-line no-unused-vars
      const iosBuyerCreated = buyerId ? await ConfigAPI.updateIosBuyer(iosBuyer, buyerId) : await ConfigAPI.createIosBuyer(iosBuyer);
      this.setState({ apiSuccess: true, creatingIosBuyer: false });
    } catch (error) {
      if (error.response.status === 400) {
        this.setState({ apiError: true, errorMessage: error.response.data.non_field_errors[0], creatingIosBuyer: false });
      } else {
        this.setState({ apiError: true, errorMessage: buyerId ? 'Error while updateting Ios Buyer' : 'Error creating Ios Buyer', creatingIosBuyer: false });
      }
    }
    return true;
  }

  redirectToBack() {
    this.props.setActiveIndex(2);
    this.props.history.push('/admin');
  }

  render() {
    const {
      iosBuyer,
      clientsLoading,
      clientOptions,
      selectedClient,
      clientGroupOptions,
      selectedClientGroup,
      errorMessage,
      creatingIosBuyer,
      clientGroupLoading,
      buyerLevels,
      selectedLevel,
      fetching
    } = this.state;
    const { buyerId } = this.props.match.params;
    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />
        <div id="AdminPage">
          <Dimmer inverted active={fetching}>
            <Loader size="large" inline="centered" />
          </Dimmer>
          <div className="title-breadcrumb">
            <Header as="h1">Investment Operating System</Header>
            <OMGBreadcrumb />
          </div>

          <div id="container">
            <div id="IosBuyerCreate">
              <Grid>
                <Grid.Column>
                  <Header as="h3">IOS Advertisers</Header>
                  <Button className="top-back-btn" basic onClick={this.redirectToBack}>
                    <Icon name="arrow left" />
                    Back
                  </Button>
                  <hr />
                  <div>
                    <h2>
                      <b> IOS Advertiser Management </b>
                    </h2>
                    <p>
                      {' '}
                      { buyerId ? 'Update' : 'Add' }
                      {' '}
IOS Advertisers
                    </p>

                    <h5 className="user-label">IOS Advertiser</h5>
                    <Input placeholder="IOS Advertiser" className="ios-buyer-field" value={iosBuyer} onChange={this.buyerEntered} />

                    <h5 className="user-label">IOS Advertiser Level</h5>
                    <Dropdown
                      placeholder="Select IOS Advertiser Level"
                      selection
                      className="ios-buyer-field"
                      options={buyerLevels}
                      value={selectedLevel}
                      onChange={this.buyerLevelSelected}
                    />
                    <br />
                    <div className="client-or-group">
                      <h5 className="user-label">Select Client Group</h5>
                      <Dropdown
                        placeholder="Client Group"
                        selection
                        className="ios-buyer-field"
                        options={clientGroupOptions}
                        value={selectedClientGroup}
                        onChange={this.clientGroupSelected}
                        loading={clientGroupLoading}
                      />

                      <Divider horizontal className="client-divider ios-buyer-field">Or</Divider>

                      <h5 className="user-label client-group">Select Client</h5>
                      <Dropdown
                        placeholder="Client Level"
                        selection
                        className="ios-buyer-field"
                        options={clientOptions}
                        value={selectedClient}
                        onChange={this.clientSelected}
                        loading={clientsLoading}
                      />

                    </div>
                  </div>
                  <Button
                    primary
                    className="user-submit-btn"
                    onClick={this.addUpdateIosBuyer}
                    loading={creatingIosBuyer}
                    disabled={creatingIosBuyer}
                  >
                    { buyerId ? 'Update ' : 'Add ' }
                     IOS Advertiser
                  </Button>
                  <Button className="user-submit-btn" onClick={this.redirectToBack}>
                  Cancel
                  </Button>

                  {this.state.apiError && (<Message color="red">{errorMessage}</Message>)}
                  {this.state.apiSuccess && (
                    <Message color="green">
Ios Advertiser
                      {' '}
                      {buyerId ? 'updated' : ' created'}
                    </Message>
                  )}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// IosBuyerAddPage.defaultProps = {
//   // email: 'Anonymous',
//   selectedAgency: null
// };

IosBuyerAddPage.propTypes = {
  email: PropTypes.string.isRequired,
  // selectedAgency: PropTypes.number
  setActiveIndex: PropTypes.func.isRequired
};

export default connect(
  store => ({
    email: localStorage.getItem('iosEmail'),
    selectedAgency: store.breadcrumb.selectedAgency
  }),
  {
    setActiveIndex
  }
)(withRouter(IosBuyerAddPage));

import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import './ZeroForecastsFilter.css';
import PropTypes from 'prop-types';

function ZeroForecastsFilter(props) {
  return (
    <Checkbox
      className="summary-filter"
      label="Show zero forecast clients"
      onChange={(e, { checked }) => props.onChangeZeroForecasts(checked)}
      checked={!!props.showZeroForecastClients}
    />
  );
}

ZeroForecastsFilter.propTypes = {
  showZeroForecastClients: PropTypes.number.isRequired,
  onChangeZeroForecasts: PropTypes.func.isRequired
};

export default ZeroForecastsFilter;

import React from 'react';
import './FilterSummary.css';
import { Grid } from 'semantic-ui-react';
import Proptypes from 'prop-types';

function FilterSummary(props) {
  return (
    <Grid id="filter-summary">
      <Grid.Column width={8} />
      <Grid.Column width={4}></Grid.Column>
      <Grid.Column width={4}>
        <p>
          <span className="bold-title">Client: </span>
          {props.summaryData.brand}
        </p>
        <p>
          <span className="bold-title">Media Name: </span>
          {props.summaryData.media}
        </p>
        <p>
          <span className="bold-title">Booking Category: </span>
          {props.summaryData.booking}
        </p>
      </Grid.Column>
    </Grid>
  );
}

FilterSummary.propTypes = {
  // eslint-disable-next-line react/require-default-props
  summaryData: Proptypes.shape({
    brand: Proptypes.string,
    clientGroup: Proptypes.string,
    agencyTeam: Proptypes.string,
    media: Proptypes.string,
    booking: Proptypes.string
  })
};
export default FilterSummary;

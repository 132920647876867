import {
  SAVE_SUMMARY_FILTERS_DATA,
  CHANGE_DEAL_TYPE,
  CHANGE_TIME_PERIOD,
  CHANGE_MONTH,
  TOGGLE_ZERO_FORECAST_CLIENTS
} from '../types/summaryFiltersTypes';


export const saveSummaryFiltersData = data => dispatch => {
  dispatch({
    type: SAVE_SUMMARY_FILTERS_DATA,
    data
  });
};

export const changeDealType = data => dispatch => {
  dispatch({
    type: CHANGE_DEAL_TYPE,
    data
  });
};

export const changeTimePeriod = data => dispatch => {
  dispatch({
    type: CHANGE_TIME_PERIOD,
    data
  });
};

export const changeMonth = data => dispatch => {
  dispatch({
    type: CHANGE_MONTH,
    data
  });
};

export const toggleZeroForecastClients = data => dispatch => {
  dispatch({
    type: TOGGLE_ZERO_FORECAST_CLIENTS,
    data
  });
};

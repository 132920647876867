import {
  SAVE_YEARLY_ACTUALS_TOTALS,
  SAVE_YEARLY_FORECAST_TOTALS,
  SAVE_MONTHLY_ACTUALS_TOTALS,
  SAVE_MONTHLY_FORECAST_TOTALS
} from '../types/totalSpendTypes';

export const saveYearlyForecastTotal = data => dispatch => {
  dispatch({
    type: SAVE_YEARLY_FORECAST_TOTALS,
    data
  });
};

export const saveYearlyActualsTotal = data => dispatch => {
  dispatch({
    type: SAVE_YEARLY_ACTUALS_TOTALS,
    data
  });
};

export const saveMonthlyForecastTotal = data => dispatch => {
  dispatch({
    type: SAVE_MONTHLY_FORECAST_TOTALS,
    data
  });
};

export const saveMonthlyActualsTotal = data => dispatch => {
  dispatch({
    type: SAVE_MONTHLY_ACTUALS_TOTALS,
    data
  });
};

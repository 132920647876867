import React from 'react';
import { Button } from 'semantic-ui-react';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';

function TimePeriodFilter(props) {
  const { data: timePeriodOptions, selectedTimePeriod } = props.timePeriodData;

  const buttons = timePeriodOptions.map(timePeriod => (
    <Button
      key={timePeriod}
      id={timePeriod}
      primary={selectedTimePeriod === timePeriod}
    >
      {upperFirst(timePeriod.replace(/_/g, ' '))}
    </Button>
  ));

  return (
    <>
      <span id="time-period-label">Show figures as of:</span>
      <br />
      <Button.Group
        fluid
        onClick={(e, data) => props.onChangeTimePeriod(e, data)}
      >
        {buttons}
      </Button.Group>
    </>
  );
}

TimePeriodFilter.propTypes = {
  timePeriodData: PropTypes.shape({
    data: PropTypes.array,
    selectedTimePeriod: PropTypes.string
  }).isRequired,
  onChangeTimePeriod: PropTypes.func.isRequired
};

export default TimePeriodFilter;

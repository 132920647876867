import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import './FinaliseMonthCheckbox.css';

function FinaliseMonthCheckbox(props) {
  return (
    <Checkbox
      label="Month Finalised"
      onChange={() => props.onChangeCheckbox(props.month, true)}
      aria-label="Finalise Month"
    />
  );
}

FinaliseMonthCheckbox.propTypes = {
  month: PropTypes.string.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired
};

export default FinaliseMonthCheckbox;

import types from '../types/breadcrumbTypes';

const initialState = {
  options: [],
  selectedMarket: null,
  selectedParentGroup: null,
  selectedAgency: null
};

export default function breadcrumb(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_BREADCRUMB_OPTIONS:
      return { ...state, options: action.data };

    case types.CHANGE_BREADCRUMB_MARKET:
      return { ...state, selectedMarket: action.data };

    case types.CHANGE_BREADCRUMB_PARENT_GROUP:
      return { ...state, selectedParentGroup: action.data };

    case types.CHANGE_BREADCRUMB_AGENCY:
      return { ...state, selectedAgency: action.data };

    default:
      return state;
  }
}

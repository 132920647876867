import types from '../types/authTypes';

const initialState = {
  loginUserInfo: null
};

export default function loginUserInfo(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_LOGIN_USER_INFO:
      return { ...state, loginUserInfo: action.data };

    default:
      return state;
  }
}

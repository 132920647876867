import {
  SAVE_SUMMARY_FILTERS_DATA,
  CHANGE_DEAL_TYPE,
  CHANGE_TIME_PERIOD,
  CHANGE_MONTH,
  TOGGLE_ZERO_FORECAST_CLIENTS
} from '../types/summaryFiltersTypes';

import { MONTHS, TIME_PERIODS } from '../constants';

const initialState = {
  dealTypes: {
    data: [],
    selectedDealType: null
  },
  // timePeriods and months come from constants not API
  timePeriods: {
    data: [...TIME_PERIODS],
    selectedTimePeriod: TIME_PERIODS[0]
  },
  months: {
    data: [...MONTHS],
    selectedMonth: null
  },
  showZeroForecastClients: 1
};

export default function summaryFilters(state = initialState, action) {
  switch (action.type) {
    case SAVE_SUMMARY_FILTERS_DATA:
      return {
        dealTypes: {
          ...state.dealTypes,
          ...action.data.deal_types
        },
        timePeriods: {
          ...state.timePeriods
        },
        months: {
          ...state.months
        },
        showZeroForecastClients: state.showZeroForecastClients
      };

    case CHANGE_DEAL_TYPE:
      return { ...state, dealTypes: { ...state.dealTypes, selectedDealType: action.data } };

    case CHANGE_TIME_PERIOD:
      return { ...state, timePeriods: { ...state.timePeriods, selectedTimePeriod: action.data } };

    case CHANGE_MONTH:
      return { ...state, months: { ...state.months, selectedMonth: action.data } };

    case TOGGLE_ZERO_FORECAST_CLIENTS:
      return { ...state, showZeroForecastClients: action.data };

    default:
      return state;
  }
}

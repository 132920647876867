import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import {
  changeBrand
} from '../../actions/menuFiltersActions';
import makeDropdownOptions from '../../lib/makeDropdownOptions';


export class ClientFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: []
    };

    this.changeBrand = this.changeBrand.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
  }

  componentDidMount() {
    if (this.props.brands.data.length) {
      this.changeBrand(this.props.brands.data[0].id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.brands.data !== this.props.brands.data) {
      this.changeBrand(this.props.brands.data[0].id);
    }
  }

  changeBrand(id) {
    this.props.changeBrand(id);
    this.setState({ brands: this.props.brands.data });
  }

  handleBrandChange(e, { value }) {
    this.changeBrand(value);
  }

  render() {
    return (
      <div id="ClientFilters">
        <span id="brand-label">Client</span>
        <Dropdown
          fluid
          selection
          loading={!this.state.brands.length}
          placeholder="Select a Brand"
          options={makeDropdownOptions(this.state.brands, 'name', 'id')}
          value={this.props.brands.selectedBrand}
          onChange={this.handleBrandChange}
          aria-labelledby="brand-label"
        />
      </div>
    );
  }
}

ClientFilters.propTypes = {
  brands: PropTypes.exact({
    data: PropTypes.array,
    selectedAgencyTeam: PropTypes.number,
    selectedClient: PropTypes.number,
    selectedBrand: PropTypes.number
  }).isRequired,
  changeBrand: PropTypes.func.isRequired
};

export default connect(
  store => ({
    brands: store.menuFilters.brands,
    forecastSpendData: store.forecastSpendData
  }),
  {
    changeBrand
  }
)(ClientFilters);

import types from '../types/authTypes';
import AuthAPI from '../api/AuthAPI';

export const login = credentials => async dispatch => {
  const user = await AuthAPI.login(credentials);
  localStorage.setItem('iosEmail', user.email);
  localStorage.setItem('iosToken', `Token ${user.token}`);
  localStorage.setItem('iosPersonId', user.person_id);
  localStorage.setItem('iosRole', user.role);

  dispatch({
    type: types.USER_LOGGED_IN,
    data: user
  });

  dispatch({
    type: types.SAVE_LOGIN_USER_INFO,
    data: user
  });
};

export const devLogin = credentials => async dispatch => {
  const user = await AuthAPI.devLogin(credentials);
  localStorage.setItem('iosEmail', user.email);
  localStorage.setItem('iosToken', `Token ${user.token}`);
  localStorage.setItem('iosPersonId', user.person_id);
  localStorage.setItem('iosRole', user.role);

  dispatch({
    type: types.USER_LOGGED_IN,
    data: user
  });

  dispatch({
    type: types.SAVE_LOGIN_USER_INFO,
    data: user
  });
};

export const logout = () => dispatch => {
  localStorage.removeItem('iosEmail');
  localStorage.removeItem('iosToken');
  localStorage.removeItem('iosPersonId');
  localStorage.removeItem('iosRole');

  dispatch({
    type: types.USER_LOGGED_OUT
  });
};


export const saveLoginUserInfo = data => dispatch => {
  dispatch({
    type: types.SAVE_LOGIN_USER_INFO,
    data
  });
};

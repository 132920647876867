import axios from 'axios';

const ConfigAPI = {
  getMediaNames: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}config-tables/${agencyId}/table/media-names/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getIosBuyers: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}config-tables/${agencyId}/table/ios-buyers/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  createIosBuyer: async buyerDetails => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}agency/${buyerDetails.agency}/buyers/`,
      buyerDetails,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken'),
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  },
  updateIosBuyer: async (buyerDetails, buyerId) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/buyers/${buyerId}`,
      buyerDetails,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken'),
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  },
  getIosVendors: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}config-tables/${agencyId}/table/ios-vendors/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getIosVendorMapping: async vendorId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}config-tables/999/table/ios-vendor-mapping/${vendorId}/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getAgencyClients: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/${agencyId}/clients/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getAgencyClientGroups: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/${agencyId}/client-groups/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getIosBuyerDetails: async (agencyId, buyerId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/${agencyId}/buyer/${buyerId}`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
};

export default ConfigAPI;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid, Header, Table, Loader, Message, Dropdown
} from 'semantic-ui-react';
import ConfigTableAPI from '../../api/ConfigTablesAPI';

export class IosVendorMappingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorMappings: [],
      iosVendors: [],
      fetching: false,
      selectedVendor: null,
      vendorsLoading: true,
      apiError: false
    };
    this.vendorSelected = this.vendorSelected.bind(this);
  }

  componentDidMount() {
    this.fetchIosVendors();
  }

  vendorSelected(e, { value }) {
    this.setState({ selectedVendor: value });
    this.fetchIosVendorMappings(value);
  }

  async fetchIosVendors() {
    this.setState({ apiError: false, vendorsLoading: true, iosVendors: [] });
    try {
      let iosVendors = await ConfigTableAPI.getIosVendors(999);
      iosVendors = iosVendors.map(ag => ({
        key: ag.id,
        text: ag.name,
        value: ag.id
      }));
      this.setState({ iosVendors, vendorsLoading: false });
    } catch (error) {
      this.setState({ vendorsLoading: false, apiError: true });
    }
  }

  async fetchIosVendorMappings(vendorId) {
    this.setState({ apiError: false, fetching: true, vendorMappings: [] });
    try {
      const vendorMappings = await ConfigTableAPI.getIosVendorMapping(vendorId);
      this.setState({ vendorMappings, fetching: false });
    } catch (error) {
      this.setState({ fetching: false, apiError: true });
    }
  }

  render() {
    const {
      iosVendors, fetching, vendorMappings, apiError, selectedVendor, vendorsLoading
    } = this.state;
    return (
      <div id="VendorMapping">
        <Grid>
          <Grid.Column>
            <Header as="h2"><b>Supplier Group with their mappings</b></Header>
            <p>All known supplier groups with media, booking, sales-house, folio, supplier mappings.</p>
            <Dropdown
              placeholder="Select Supplier Group"
              selection
              options={iosVendors}
              value={selectedVendor}
              onChange={this.vendorSelected}
              loading={vendorsLoading}
            />
            <Loader active={fetching} inline="centered" />
            {
              !!vendorMappings.length && (
                <Table celled padded striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell singleLine>Supplier Group</Table.HeaderCell>
                      <Table.HeaderCell>Media</Table.HeaderCell>
                      <Table.HeaderCell>Booking</Table.HeaderCell>
                      <Table.HeaderCell>Saleshouse</Table.HeaderCell>
                      <Table.HeaderCell>Folio</Table.HeaderCell>
                      <Table.HeaderCell>Supplier</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {vendorMappings.map((mn, index) => (
                      <tr key={index}>
                        <td>{mn.vendor_name}</td>
                        <td>{mn.media_name}</td>
                        <td>{mn.booking_name}</td>
                        <td>{mn.sales_house_name}</td>
                        <td>{mn.folio_name}</td>
                        <td>{mn.supplier_name}</td>
                      </tr>
                    ))}
                  </Table.Body>
                </Table>
              )
            }

            {apiError && (<Message color="red">Error fetching supplier groups</Message>)}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

IosVendorMappingPage.defaultProps = {
  selectedAgency: null
};
IosVendorMappingPage.propTypes = {
  selectedAgency: PropTypes.number
};

export default connect(
  store => ({
    selectedAgency: store.breadcrumb.selectedAgency
  }),
  {}
)(IosVendorMappingPage);

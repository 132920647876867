import axios from 'axios';

const SummaryFiltersAPI = {
  getDealTypes: async () => {
    const dealTypes = await axios.get(process.env.REACT_APP_SUMMARY_FILTERS_URL, {
      headers: {
        Authorization: localStorage.getItem('iosToken')
      }
    });
    return dealTypes.data;
  }
};

export default SummaryFiltersAPI;

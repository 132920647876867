import {
  SAVE_MENU_FILTERS_DATA,
  CHANGE_AGENCY_TEAM,
  CHANGE_CLIENT,
  CHANGE_BRAND,
  CHANGE_MEDIA,
  CHANGE_BOOKING,
  CHANGE_YEAR
} from '../types/menuFiltersTypes';


export const saveMenuFiltersData = data => dispatch => {
  dispatch({
    type: SAVE_MENU_FILTERS_DATA,
    data
  });
};

export const changeAgencyTeam = data => dispatch => {
  dispatch({
    type: CHANGE_AGENCY_TEAM,
    data
  });
};

export const changeClient = data => dispatch => {
  dispatch({
    type: CHANGE_CLIENT,
    data
  });
};

export const changeBrand = data => dispatch => {
  dispatch({
    type: CHANGE_BRAND,
    data
  });
};

export const changeMediaName = data => dispatch => {
  dispatch({
    type: CHANGE_MEDIA,
    data
  });
};

export const changeBookingCategory = data => dispatch => {
  dispatch({
    type: CHANGE_BOOKING,
    data
  });
};

export const changeYear = data => dispatch => {
  dispatch({
    type: CHANGE_YEAR,
    data
  });
};

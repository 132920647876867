import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  saveBreadcrumbOptions,
  changeBreadcrumbMarket,
  changeBreadcrumbParentGroup,
  changeBreadcrumbAgency
} from '../../actions/breadcrumbActions';
import MenuFiltersAPI from '../../api/MenuFiltersAPI';
import './OMGBreadcrumb.css';

export class OMGBreadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markets: [],
      parentGroups: [],
      agencies: []
    };

    this.getBreadcrumbOptions = this.getBreadcrumbOptions.bind(this);
    this.handleMarketChange = this.handleMarketChange.bind(this);
    this.handleParentGroupChange = this.handleParentGroupChange.bind(this);
    this.handleAgencyChange = this.handleAgencyChange.bind(this);
  }

  componentDidMount() {
    this.getBreadcrumbOptions();
  }

  async getBreadcrumbOptions() {
    // get all options
    const breadcrumb = this.props.breadcrumb.options.length
      ? this.props.breadcrumb.options
      : await MenuFiltersAPI.getBreadcrumb();

    // if a market is selected, use it
    // otherwise use the first one
    let selectedMarket = breadcrumb.find(m => (
      m.id === this.props.breadcrumb.selectedMarket
    ));

    selectedMarket = selectedMarket
      ? selectedMarket.id
      : breadcrumb[0].id;

    this.props.saveBreadcrumbOptions(breadcrumb);
    this.setState({ markets: breadcrumb }, () => this.changeMarket(selectedMarket));
  }

  changeMarket(id) {
    // get parent group options for given market
    const parentGroups = this.state.markets.find(market => market.id === id).parent_groups;

    // if a parent group is selected, use it
    // otherwise use the first one
    let selectedParentGroup = parentGroups.find(pg => (
      pg.id === this.props.breadcrumb.selectedParentGroup
    ));

    selectedParentGroup = selectedParentGroup
      ? selectedParentGroup.id
      : parentGroups[0].id;

    this.props.changeBreadcrumbMarket(id);
    this.setState({ parentGroups }, () => this.changeParentGroup(selectedParentGroup));
  }

  changeParentGroup(id) {
    // get agency options for dropdown menu
    const agencies = this.state.parentGroups.find(parentGroup => parentGroup.id === id).agencies;

    let selectedAgency = agencies.find(a => (
      a.id === this.props.breadcrumb.selectedAgency
    ));

    selectedAgency = selectedAgency
      ? selectedAgency.id
      : agencies[0].id;

    this.props.changeBreadcrumbParentGroup(id);
    this.setState({ agencies }, () => this.changeAgency(selectedAgency));
  }

  changeAgency(id) {
    // const selectedAgency = agencyOptions[0].value
    this.props.changeBreadcrumbAgency(id);
  }

  handleMarketChange(e, { value }) {
    this.changeMarket(value);
  }

  handleParentGroupChange(e, { value }) {
    this.changeParentGroup(value);
  }

  handleAgencyChange(e, { value }) {
    this.changeAgency(value);
  }

  render() {
    return (
      <div id="OMGBreadcrumb">
        <b>Show information by: </b>
        <Breadcrumb>
          <Breadcrumb.Section>
            <Dropdown
              selectOnBlur={false}
              selectOnNavigation={false}
              openOnFocus={false}
              options={this.state.markets.reduce((acc, cur) => {
                acc.push({ text: cur.name, value: cur.id });
                return acc;
              }, [])}
              value={this.props.breadcrumb.selectedMarket}
              onChange={this.handleMarketChange}
              aria-label={"Market"}
            />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon='right chevron' />

          <Breadcrumb.Section>
            <Dropdown
              selectOnBlur={false}
              selectOnNavigation={false}
              openOnFocus={false}
              options={this.state.parentGroups.reduce((acc, cur) => {
                acc.push({ text: cur.name, value: cur.id });
                return acc;
              }, [])}
              value={this.props.breadcrumb.selectedParentGroup}
              onChange={this.handleParentGroupChange}
              aria-label={"Agency Parent Group"}
            />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon='right chevron' />

          <Breadcrumb.Section>
            <Dropdown
              selectOnBlur={false}
              selectOnNavigation={false}
              openOnFocus={false}
              options={this.state.agencies.reduce((acc, cur) => {
                acc.push({ text: cur.name, value: cur.id });
                return acc;
              }, [])}
              value={this.props.breadcrumb.selectedAgency}
              onChange={this.handleAgencyChange}
              aria-label={"Agency"}
            />
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>
    );
  }
}

OMGBreadcrumb.propTypes = {
  saveBreadcrumbOptions: PropTypes.func.isRequired,
  changeBreadcrumbMarket: PropTypes.func.isRequired,
  changeBreadcrumbParentGroup: PropTypes.func.isRequired,
  changeBreadcrumbAgency: PropTypes.func.isRequired,
  breadcrumb: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ])
  ).isRequired
};

export default connect(
  store => ({
    breadcrumb: store.breadcrumb
  }),
  {
    saveBreadcrumbOptions,
    changeBreadcrumbMarket,
    changeBreadcrumbParentGroup,
    changeBreadcrumbAgency
  }
)(OMGBreadcrumb);

import React from 'react';
import './YearFilter.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import {
  changeYear
} from '../../actions/menuFiltersActions';
import makeDropdownOptions from '../../lib/makeDropdownOptions';

export class YearFilter extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.years.selectedYear !== prevProps.years.selectedYear) {
      if (this.props.triggerTableRefresh) {
        this.props.getClientActuals();
      }
    }
  }

  render() {
    const label = 'Year ';
    return (
      <>
        <span>
          {this.props.showLabel
            && (
              label
            )}
            &nbsp;
        </span>
        <Dropdown
          selection
          fluid={this.props.fluid}
          loading={!this.props.years.data.length}
          options={makeDropdownOptions(this.props.years.data)}
          value={this.props.years.selectedYear}
          onChange={(e, { value }) => this.props.changeYear(value)}
          className="yearDropdown"
        />
      </>
    );
  }
}

YearFilter.propTypes = {
  years: PropTypes.exact({
    data: PropTypes.array,
    selectedYear: PropTypes.number
  }).isRequired,
  changeYear: PropTypes.func.isRequired,
  getClientActuals: PropTypes.func,
  showLabel: PropTypes.bool.isRequired,
  fluid: PropTypes.bool.isRequired,
  triggerTableRefresh: PropTypes.bool.isRequired
};

YearFilter.defaultProps = {
  getClientActuals: null
};

export default connect(
  store => ({
    years: store.menuFilters.years
  }),
  {
    changeYear
  }
)(YearFilter);

import React from 'react';
import { Table } from 'semantic-ui-react'

export class SummaryTable extends React.Component {
  render() {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
          </Table.Row>
        </Table.Header>
        <Table.Body>

        </Table.Body>
      </Table>
    )
  }
}

export default SummaryTable;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid, Header, Table, Loader, Message
} from 'semantic-ui-react';
import ConfigTableAPI from '../../api/ConfigTablesAPI';

export class MediaNamePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaNames: [],
      fetching: true,
      apiError: false
    };
  }

  componentDidMount() {
    if (this.props.selectedAgency) {
      this.fetchMediaNames(this.props.selectedAgency);
    }
  }

  componentDidUpdate(previous) {
    if (previous.selectedAgency !== this.props.selectedAgency) {
      this.fetchMediaNames(this.props.selectedAgency);
    }
  }

  async fetchMediaNames() {
    this.setState({ apiError: false, fetching: true, mediaNames: [] });
    try {
      const mediaNames = await ConfigTableAPI.getMediaNames(this.props.selectedAgency);
      this.setState({ mediaNames, fetching: false });
    } catch (error) {
      this.setState({ fetching: false, apiError: true });
    }
  }

  render() {
    return (
      <div id="MediaNames">
        <Grid>
          <Grid.Column>
            <Header as="h2"><b>IOSMediaNames with associated Media and Booking names</b></Header>
            <p>Bespoke IOSMediaNames and relevant Media and Booking names associated to them.</p>
            <Loader active={this.state.fetching} inline="centered" />
            {
              !!this.state.mediaNames.length && (
                <Table celled padded striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell singleLine>IOSMediaName</Table.HeaderCell>
                      <Table.HeaderCell>Media</Table.HeaderCell>
                      <Table.HeaderCell>Booking</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.mediaNames.map((mn, index) => (
                      <tr key={index}>
                        <td>{mn.name}</td>
                        <td>{mn.media_name}</td>
                        <td>{mn.booking_name}</td>
                      </tr>
                    ))}
                  </Table.Body>
                </Table>
              )
            }

            {this.state.apiError && (<Message color="red">Error fetching media names</Message>)}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

MediaNamePage.defaultProps = {
  selectedAgency: null
};
MediaNamePage.propTypes = {
  selectedAgency: PropTypes.number
};

export default connect(
  store => ({
    selectedAgency: store.breadcrumb.selectedAgency
  }),
  {}
)(MediaNamePage);

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import PropTypes from 'prop-types';
import HomePage from './components/HomePage/HomePage';
import ForecastUploadPage from './components/ForecastUpload/ForecastUploadPage';
import ForecastMatchActualsPage from './components/ForecastMatchActuals/ForecastMatchActualsPage';
import LoginPage from './components/LoginPage/LoginPage';
import DevLoginPage from './components/LoginPage/DevLoginPage';
import AdminPage from './components/AdminPage/AdminPage';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import UserDetailsPage from './components/UserManagement/UserDetailsPage';
import UserAdd from './components/UserManagement/UserAdd';
import Error404 from './components/ErrorPages/Error404';
import Error403 from './components/ErrorPages/Error403';
import IosBuyerAddPage from './components/AdminPage/IosBuyerAddPage';

function App({ location }) {
  return (
    <div>
      <Router>
        <Switch>
          <ProtectedRoute
            location={location}
            path="/"
            exact
            component={HomePage}
            tab="data-management"
          />

          <ProtectedRoute
            location={location}
            path="/forecasting"
            exact
            component={HomePage}
            tab="forecasting"
          />

          <ProtectedRoute
            location={location}
            path="/value-tracking"
            exact
            component={HomePage}
            tab="value-tracking"
          />

          <ProtectedRoute
            location={location}
            path="/planning"
            exact
            component={HomePage}
            tab="planning"
          />

          <ProtectedRoute
            location={location}
            path="/forecast-upload"
            exact
            component={ForecastUploadPage}
            tab="None"
          />

          <ProtectedRoute
            location={location}
            path="/forecast-match-actuals"
            exact
            component={ForecastMatchActualsPage}
            tab="None"
          />

          <ProtectedRoute
            location={location}
            path="/agency/:agencyId/user/:userId/"
            exact
            component={UserDetailsPage}
            tab="None"
          />

          <ProtectedRoute
            location={location}
            path="/users/new"
            exact
            component={UserAdd}
            tab="None"
          />

          <ProtectedRoute
            location={location}
            path="/ios-advertisers/new"
            exact
            component={IosBuyerAddPage}
            tab="None"
          />
          <ProtectedRoute
            location={location}
            path="/agency/:agencyId/ios-advertisers/:buyerId"
            exact
            component={IosBuyerAddPage}
            tab="None"
          />
          <ProtectedRoute
            location={location}
            path="/admin"
            exact
            component={AdminPage}
            tab="user-management"
          />

          <Route path="/login" exact location={location} component={LoginPage} />
          <Route path="/dev-login" exact location={location} component={DevLoginPage} />
          <Route path="/unauthorized" exact location={location} component={Error403} />

          <Route path="*" component={Error404} />

        </Switch>
      </Router>
    </div>
  );
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default App;

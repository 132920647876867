import axios from 'axios';

const AuthAPI = {
  login: async credentials => {
    const authURL = process.env.REACT_APP_AD_AUTH_URL;
    const authData = {
      username: credentials.username,
      password: credentials.password
    };

    const userData = await axios.post(authURL, authData);
    return userData.data;
  },

  devLogin: async credentials => {
    const authURL = `${process.env.REACT_APP_API_URL}api-token-auth/`;
    const authData = {
      email: credentials.email,
      password: credentials.password
    };

    const userData = await axios.post(authURL, authData);
    return userData.data;
  },

  isTokenAuthentic: async () => {
    if (localStorage.getItem('iosEmail') && localStorage.getItem('iosToken')) {
      const response = await axios
        .get(process.env.REACT_APP_CHECK_CREDENTIALS_URL, {
          headers: {
            Authorization: localStorage.iosToken,
            'Content-Type': 'application/json'
          }
        })
        .catch(() => Promise.resolve(false));

      if (response !== false) {
        const { data } = response;

        // Sync local storage with data returned from API
        // this prevents the tampered data in local storage being used
        localStorage.setItem('iosEmail', data.email);
        localStorage.setItem('iosToken', `Token ${data.token}`);
        localStorage.setItem('iosPersonId', data.person_id);
        localStorage.setItem('iosRole', data.role);
      }
      return Promise.resolve(response);
    }
    return Promise.resolve(false);
  }
};

export default AuthAPI;

import {
  SAVE_FINALISED_MONTHS_DATA,
  UPDATE_MONTH_FINALISED_STATUS
} from '../types/finalisedMonthsTypes';

const initialState = {};

export default function finalisedMonths(state = initialState, action) {
  switch (action.type) {
    case SAVE_FINALISED_MONTHS_DATA:
      return { ...action.data };

    case UPDATE_MONTH_FINALISED_STATUS: {
      const { month } = action.data;
      const updatedState = {
        ...state,
        [month]: {
          ...state[action.data.month],
          finalized: action.data.finalized,
          changed: true
        }
      };

      return { ...updatedState };
    }


    default:
      return state;
  }
}

import {
  SAVE_FINALISED_MONTHS_DATA,
  UPDATE_MONTH_FINALISED_STATUS
} from '../types/finalisedMonthsTypes';

export const saveFinalisedMonthsData = data => dispatch => {
  dispatch({
    type: SAVE_FINALISED_MONTHS_DATA,
    data
  });
};

export const updateMonthFinalisedStatus = data => dispatch => {
  dispatch({
    type: UPDATE_MONTH_FINALISED_STATUS,
    data
  });
};

import React from 'react';
import { connect } from 'react-redux';
import { Header, Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import OMGBreadcrumb from '../OMGBreadcrumb/OMGBreadcrumb';
import './AdminPage.css';
import UserManagementPage from '../UserManagement/UserManagementPage';
import MediaName from './MediaNamePage';
import IosBuyerMappingPage from './IosBuyerMappingPage';
import IosVendorMappingPage from './IosVendorMappingPage';
import isUserAdmin from '../../lib/isAdminUser';
import { setActiveIndex } from '../../actions/adminPageAction';

export class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panes: [
        {
          menuItem: { name: 'User Management', key: 'user-management' },
          tab: 'user-management',
          render: () => <Tab.Pane attached={false}><UserManagementPage /></Tab.Pane>
        },
        {
          menuItem: {
            name: 'Media Names', key: 'media-names'
          },
          tab: 'media-names',
          render: () => <Tab.Pane attached={false}><MediaName /></Tab.Pane>
        },
        {
          menuItem: {
            name: 'Advertiser Mapping', key: 'advertiser-mapping'
          },
          tab: 'advertiser-mapping',
          render: () => <Tab.Pane attached={false}><IosBuyerMappingPage /></Tab.Pane>
        },
        {
          menuItem: {
            name: 'Supplier Group Mapping', key: 'supplier-group-mapping'
          },
          tab: 'supplier-group-mapping',
          render: () => <Tab.Pane attached={false}><IosVendorMappingPage /></Tab.Pane>
        }
      ],
      activeIndex: 0
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    if (!isUserAdmin(localStorage.iosRole)) {
      this.props.history.push('/unauthorized');
    }
    if (this.props.adminPageInfo.activeIndex != null) {
      this.setState({ activeIndex: this.props.adminPageInfo.activeIndex });
    }
  }


  handleTabChange(e, { activeIndex }) {
    this.setState({ activeIndex });
    this.props.setActiveIndex(activeIndex);
  }


  render() {
    const { activeIndex } = this.state;
    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />

        <div id="AdminPage">
          <div className="title-breadcrumb">
            <Header as="h1">Investment Operating System</Header>
            <OMGBreadcrumb />
          </div>

          <div id="container">
            <Tab
              menu={{ secondary: true, pointing: true }}
              activeIndex={activeIndex}
              panes={this.state.panes}
              onTabChange={this.handleTabChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

AdminPage.propTypes = {
  email: PropTypes.string.isRequired,
  adminPageInfo: PropTypes.object.isRequired,
  setActiveIndex: PropTypes.func.isRequired
};

export default connect(
  store => ({
    email: localStorage.getItem('iosEmail'), // store.user.email,
    adminPageInfo: store.adminPageInfo
  }),
  {
    setActiveIndex
  }
)(withRouter(AdminPage));

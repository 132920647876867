import axios from 'axios';

const forecastMatchActualsUrl = process.env.REACT_APP_FORECASTS_MATCH_ACTUALS_URL
  ? process.env.REACT_APP_FORECASTS_MATCH_ACTUALS_URL
  : 'https://iosapi.oneomg.com/forecasts-match-actuals/';

const ForecastMatchActualsAPI = {
  matchForecastsWithActuals: async forecastData => {
    const forecastsMatchActualsMenuData = await axios.post(
      forecastMatchActualsUrl,
      forecastData,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return forecastsMatchActualsMenuData;
  },
  forecastMatchWithActualsMenu: async () => {
    const forecastsMatchActualsMenuData = await axios.get(
      forecastMatchActualsUrl,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return forecastsMatchActualsMenuData.data;
  }
};

export default ForecastMatchActualsAPI;

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Icon,
  Modal,
  Header,
  Button
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  saveFolioForecastSpendData,
  saveSupplierForecastSpendData,
  toggleCollapseRow,
  changeForecastValue,
  changeForecastLevel,
  changeTempForecastValue
} from '../../actions/forecastSpendDataActions';
import {
  MONTHS,
  SALESHOUSE_LEVEL,
  FOLIO_LEVEL,
  SUPPLIER_LEVEL,
  FOLIO_SUPPLIER_LEVEL,
  ACTUALS,
  FORECASTS
} from '../../constants';
import './PivotTable.css';
import PivotTableRow from '../PivotTableRow/PivotTableRow';
import FinaliseMonthCheckbox from '../FinaliseMonthCheckbox/FinaliseMonthCheckbox';
import ReopenFinalizedMonth from '../ReopenFinalizedMonth/ReopenFinalizedMonth';
import { updateMonthFinalisedStatus } from '../../actions/finalisedMonthsActions';
import PivotTableAPI from '../../api/PivotTableAPI';
import processForecastSpendData from '../../lib/processForecastSpendData';
import formatNumberValue from '../../lib/formatNumberValue';
import numberWithCommas from '../../lib/numberWithCommas';
import OMGModal from '../OMGModal/OMGModal';
import { processYearlyActuals, processYearlyForecasts } from '../../lib/processYearlyTotal';
import {
  saveYearlyForecastTotal,
  saveYearlyActualsTotal
} from '../../actions/totalSpendActions';

export class PivotTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalForecastLevel: {
        show: false,
        origLevel: '',
        editLevel: ''
      },
      // this is the ref for the input field to focus on
      // when modal window appears, depending on button clicked,
      // we want to focus on the field user was editing
      inputRefToFocus: null,
      showReopenFinalizedMonthModal: false,
      finalizedMonthToOpen: null
    };

    this.getDeeperData = this.getDeeperData.bind(this);
    this.generateRows = this.generateRows.bind(this);
    this.editLiveForecast = this.editLiveForecast.bind(this);
    // this.getForecastSpendData = this.getForecastSpendData.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.changeForecastLevel = this.changeForecastLevel.bind(this);
    this.clearChildrenForecast = this.clearChildrenForecast.bind(this);
    this.setInputRefToFocus = this.setInputRefToFocus.bind(this);
    this.updateMonthFinaliseStatus = this.updateMonthFinaliseStatus.bind(this);
    this.handleReopenFinalizedMonthClick = this.handleReopenFinalizedMonthClick.bind(this);
    this.toggleReopenFinalizedMonthModalDisplay = this.toggleReopenFinalizedMonthModalDisplay.bind(this);
    this.reopenFinalisedMonth = this.reopenFinalisedMonth.bind(this);
    this.getMonthActual = this.getMonthActual.bind(this);
    this.getMonthForecast = this.getMonthForecast.bind(this);
  }

  /**
   * Finds and returns forecast/spend data as defined by id object.
   * @param {Object} id { saleshouseId: {Int}, folioId: {Int}, supplierId: {Int} }
   */
  getForecastSpendData(id) {
    if (id.saleshouseId && !id.folioId && !id.supplierId) {
      return this.props.forecastSpendData[id.saleshouseId];
    }

    if (id.saleshouseId && id.folioId && !id.supplierId) {
      return this.props.forecastSpendData[id.saleshouseId].folios[id.folioId];
    }

    if (id.saleshouseId && id.folioId && id.supplierId) {
      return this.props.forecastSpendData[id.saleshouseId]
        .folios[id.folioId]
        .suppliers[id.supplierId];
    }
    return null;
  }

  /**
   * Get data for one level down
   * (eg. if received level='folio' and id=2, we want to receive an array of suppliers for folio
   * of id=2)
   * @param {Int} salesHouseId the sales house id (to get folios)
   * @param {Int} folioId the folio id (to get suppliers)
   */
  async getDeeperData(salesHouseId, folioId = undefined) {
    const ids = {
      sales_house_id: salesHouseId
    };
    if (folioId !== undefined) { ids.folio_id = folioId; }

    // level of the new data
    const level = [SALESHOUSE_LEVEL, FOLIO_LEVEL, SUPPLIER_LEVEL][Object.keys(ids).length];

    // if deeper data does not already exists, fetch it
    if ((!folioId && !Object.prototype.hasOwnProperty.call(this.props.forecastSpendData[salesHouseId], 'folios'))
    || (folioId && !Object.prototype.hasOwnProperty.call(this.props.forecastSpendData[salesHouseId].folios[folioId], 'suppliers'))) {
      let {
        monthlyData: deeperData,
        // eslint-disable-next-line
        yearlyData
      } = await PivotTableAPI.getClientActuals(
        this.props.getClientActualsFilters(ids)
      );

      const yearlyActuals = processYearlyActuals(
        this.props.yearlySpend.actuals, yearlyData.actuals, level
      );
      const yearlyForecasts = processYearlyForecasts(
        this.props.yearlySpend.forecasts, yearlyData.forecasts, level
      );

      this.props.saveYearlyActualsTotal(yearlyActuals);
      this.props.saveYearlyForecastTotal(yearlyForecasts);

      deeperData = processForecastSpendData(
        deeperData,
        { level, SaleshouseId: salesHouseId, FolioId: folioId }
      );

      switch (level) {
        case FOLIO_LEVEL:
          this.props.saveFolioForecastSpendData({ salesHouseId, folios: deeperData });
          break;

        case SUPPLIER_LEVEL:
          this.props.saveSupplierForecastSpendData(
            { salesHouseId, folioId, suppliers: deeperData }
          );
          break;

        default:
          break;
      }
    }

    const toggleLevel = [
      SALESHOUSE_LEVEL, FOLIO_LEVEL, SUPPLIER_LEVEL
    ][Object.keys(ids).length - 1];
    this.props.toggleCollapseRow({ level: toggleLevel, salesHouseId, folioId });
  }

  setInputRefToFocus(ref) {
    this.setState({ inputRefToFocus: ref });
  }

  /**
   * For a given month, get the forecast figure suitably formatted
   * @param {String} month
   * @returns {String}
   */
  getMonthForecast(month) {
    const monthForecastsData = this.props.monthlySpend.forecasts
      .find(k => k.month === month);

    const forecast = monthForecastsData !== undefined ? numberWithCommas(monthForecastsData.forecast) : '';

    return forecast !== '0.00' ? forecast : '';
  }

  /**
   * For a given month, get the actual figure suitably formatted
   * @param {String} month
   * @returns {String}
   */
  getMonthActual(month) {
    const monthActualsData = this.props.monthlySpend.actuals
      .find(k => k.month === month);

    const actual = monthActualsData !== undefined ? numberWithCommas(monthActualsData.actual) : '';

    return actual !== '0.00' ? actual : '';
  }

  /**
   * Fetch the yearly total for Actuals or Forecasts based on the totalType flag.
   * @param {*} totalType String Flag to return forecast or actual total
   * @returns String formatted total
   */
  getYearTotal(totalType) {
    let yearTotal;

    if (totalType === ACTUALS) {
      yearTotal = this.props.monthlySpend.actuals.reduce((prev, cur) => prev + parseFloat(cur.actual), 0);
    }
    if (totalType === FORECASTS) {
      yearTotal = this.props.monthlySpend.forecasts.reduce((prev, cur) => prev + parseFloat(cur.forecast), 0);
    }

    return yearTotal ? numberWithCommas(yearTotal) : '';
  }

  updateMonthFinaliseStatus(month, finaliseStatus) {
    const data = { month, finalized: finaliseStatus };
    this.props.updateMonthFinalisedStatus(data);
  }

  handleReopenFinalizedMonthClick(month) {
    this.setState({ finalizedMonthToOpen: month }, () => {
      this.toggleReopenFinalizedMonthModalDisplay();
    });
  }

  toggleReopenFinalizedMonthModalDisplay() {
    this.setState(state => ({
      showReopenFinalizedMonthModal: !state.showReopenFinalizedMonthModal
    }));
  }

  reopenFinalisedMonth() {
    this.updateMonthFinaliseStatus(this.state.finalizedMonthToOpen, false);
    // close modal
    this.toggleReopenFinalizedMonthModalDisplay();
  }

  /**
   * Recursively clear forecast value and level of all children of provided cell.
   * @param {Object} parentId id object of parent of children that need updating
   */
  clearChildrenForecast(parentId) {
    const parent = this.getForecastSpendData(parentId);
    const childrenKeys = {
      Saleshouse: 'folios',
      Folio: 'suppliers'
    };
    const children = parent[childrenKeys[parent.level]];
    if (!children) { return; }

    Object.values(children).map(child => {
      const id = {
        saleshouseId: child.SaleshouseId,
        folioId: child.FolioId,
        supplierId: child.SupplierId,
        month: parentId.month
      };

      this.props.changeForecastValue({
        ...id,
        level: child.level,
        forecast: null
      });

      this.props.changeForecastLevel({
        ...id,
        level: child.level,
        forecastLevel: null
      });

      this.clearChildrenForecast(id);

      return true;
    });
  }

  /**
   * Makes the forecast level change. This method is called when clicking on the 'Add <level>
   * Figures'button.
   * - Changes forecast level of parent of edited cell to edited cell's level
   * - Changes forecast level of edited cell to its level
   * - Clears children forecast levels and values
   * @param {Object} id id of changed item/row
   * @param {String} newLevel new level, the one of the edited cell
   * @param {Number} newValue new value, the one entered in the edited cell
   */
  changeForecastLevel(id, newLevel, newValue) {
    // update parent forecast level with new level
    const parentId = this.findParent(id);
    const parent = this.getForecastSpendData(parentId);

    if (parent) {
      this.props.changeForecastLevel({
        level: parent.level,
        ...parentId,
        forecastLevel: newLevel
      });
    }

    // update edited row forecast level with new level
    this.props.changeForecastLevel({
      level: newLevel,
      ...id,
      forecastLevel: newLevel
    });

    // update its children's forecast level and null value
    this.clearChildrenForecast(id);

    // propagate new value and sum
    this.propagateUpForecastChange(id, formatNumberValue(newValue), newLevel);

    // hide modal
    this.setState(
      {
        modalForecastLevel: {
          show: false,
          origLevel: '',
          editLevel: ''
        }
      }
    );
  }

  /**
   * Cancels the editing in a cell. This method is called
   * when clicking on the 'Keep <level> Figures'
   * button of the modal.
   */
  cancelEdit(id, editLevel) {
    this.props.changeTempForecastValue({
      ...id,
      level: editLevel,
      tempForecast: undefined
    });
    this.setState({
      modalForecastLevel: {
        show: false,
        origLevel: '',
        editLevel: ''
      },
      // remove focus from input
      inputRefToFocus: null
    });
  }

  /**
   * Handles an edit in a forecast value cell. It looks for a forecastLevel of the edited value or
   * of parent.
   * @param {Object} id id object of changed cell
   *  { saleshouseId: {Int}, folioId: {Int}, supplierId: {Int}, month: {Int} }
   * @param {Number} newValue
   */
  editLiveForecast(id, newValue) {
    const changedItem = this.getForecastSpendData(id);

    if (newValue === '' && !changedItem[id.month].forecast) {
      return;
    }

    const editLevel = changedItem.level;
    let forecastLevel = changedItem[id.month] && changedItem[id.month].forecastLevel
      ? changedItem[id.month].forecastLevel
      : null;

    // if forecastLevel is null, look for a parent's forecastLevel
    let parentId = this.findParent(id);
    while (parentId.saleshouseId && !forecastLevel) {
      const parent = this.getForecastSpendData(parentId);

      forecastLevel = parent && parent[id.month]
        ? parent[id.month].forecastLevel
        : null;

      if (!forecastLevel) {
        parentId = this.findParent(parentId);
      }
    }

    // IF THERE IS NO FORECAST LEVEL
    // OR IF EDITING AT THE FORECAST LEVEL, go through with the edit.
    const isCompatibleLevels = forecastLevel === FOLIO_SUPPLIER_LEVEL
      && (editLevel === FOLIO_LEVEL || editLevel === SUPPLIER_LEVEL);

    if (!forecastLevel || forecastLevel === editLevel || isCompatibleLevels) {
      // set level for changed item, its parent and clear children forecast values and levels

      const formattedValue = formatNumberValue(newValue);

      this.changeForecastLevel(id, editLevel, formattedValue);
      // propagate value up
      this.propagateUpForecastChange(id, formattedValue, editLevel);
    } /* IF EDITING AT A DIFFERENT LEVEL */ else if (forecastLevel !== editLevel) {
      // show modal for user to confirm
      this.setState(
        {
          modalForecastLevel: {
            show: true,
            forecastLevel,
            editLevel,
            changeLevelVariables: [id, editLevel, newValue]
          }
        }
      );
    }
  }

  /**
   * Recursively propagates forecast changes up the data tree. Depending on the forecast level,
   * children forecast values are summed and saved in the parent.
   * @param {Object} id cell to change
   * { saleshouseId: {Int}, folioId: {Int}, supplierId: {Int}, month: {Int} }
   * @param {Number} newValue the new forecast value to set at id
   * @param {*} newLevel
   */
  propagateUpForecastChange(id, newValue, editedLevel) {
    const changedItem = this.getForecastSpendData(id);

    // set the new value
    this.props.changeForecastValue({
      level: changedItem.level,
      ...id,
      forecast: formatNumberValue(newValue)
    });

    // set forecast level of saleshouse level to SALESHOUSE_LEVEL or FOLIO_SUPPLIER_LEVEL
    if (changedItem.level === SALESHOUSE_LEVEL) {
      this.props.changeForecastLevel({
        level: changedItem.level,
        ...id,
        forecastLevel: editedLevel === SALESHOUSE_LEVEL
          ? SALESHOUSE_LEVEL
          : FOLIO_SUPPLIER_LEVEL
      });
    }

    // finding the parent
    const parentId = this.findParent(id);
    const parent = this.getForecastSpendData(parentId);

    if (!parent) { return; }

    // find all its childen, i.e. the siblings of the changed value
    const childrenKeys = {
      Saleshouse: 'folios',
      Folio: 'suppliers'
    };
    const children = parent[childrenKeys[parent.level]];

    const sum = Object.values(children).reduce((acc, child) => {
      let forecast = 0;
      if (child[id.month].forecast) {
        forecast = formatNumberValue(child[id.month].forecast);
      }
      forecast = Number.isNaN(forecast) ? 0 : forecast;
      return acc + forecast;
    }, 0);

    // update the parent with the sum
    this.propagateUpForecastChange({ ...parentId, month: id.month }, sum, editedLevel);
  }

  /**
   * Returns parent id object of provided child id object.
   * @param {Object} id { saleshouseId: {Int}, folioId: {Int}, supplierId: {Int} }
   * @returns {Object} the parent's id object
   */
  findParent(id) {
    const idArray = [id.saleshouseId, id.folioId, id.supplierId].filter(idValue => idValue);
    idArray.splice(-1, 1);
    return {
      ...id,
      saleshouseId: idArray[0],
      folioId: idArray[1],
      supplierId: idArray[2]
    };
  }

  /**
   * Transforms a nested object of data into an array of rows.
   * @param {Object} data { <level>: <object of data> }
   * @param {Int} levelId the id level (salesHouse=0, supplier=2) (used for recursion)
   * @returns {Array}
   */
  generateRows(data, levelId = 0) {
    const levels = ['saleshouses', 'folios', 'suppliers'];
    if (!data[levels[levelId]]) {
      return data;
    }

    return Object.values(data[levels[levelId]]).map(value => {
      const nextLevel = levels[levelId + 1];
      if (nextLevel in value && value.showChildren) {
        const array = this.generateRows(value, levelId + 1);
        return [value, ...array];
      }
      return value;
    }).flat(Infinity);
  }

  /**
   * Formated a nested object of data into an array of sub rows (Sorted data).
   * @param {Object} data { <level>: <object of data> }
   * @param {Int} levelId the id level (salesHouse=0, supplier=2) (used for recursion)
   * @returns {Array}
   */
  generateGroupData(data, levelId = 0) {
    const levels = ['saleshouses', 'folios', 'suppliers'];
    if (!data[levels[levelId]]) {
      return data;
    }

    const finalData = Object.values(data[levels[levelId]]).map(value => {
      const nextLevel = levels[levelId + 1];
      if (nextLevel in value && value.showChildren) {
        const array = this.generateGroupData(value, levelId + 1);
        array.sort((a, b) => {
          if (a.orderId === null) {
            return 1;
          } if (b.orderId === null) {
            return -1;
          }
          return a.orderId - b.orderId;
        });
        value.subRows = array;
        return value;
      }
      return value;
    });
    finalData.sort((a, b) => {
      if (a.orderId === null) {
        return 1;
      } if (b.orderId === null) {
        return -1;
      }
      return a.orderId - b.orderId;
    });
    return finalData;
  }

  /**
   *
   * @param {Array} groupData main row  (Saleshour rows with sub rows)
   * @param {Int} levelId the id level (salesHouse=0, supplier=2) (used for recursion)
   * @returns {Array} formated nested array with sub rows
   */
  getFormattedData(groupData, levelId = 0) {
    const levels = ['saleshouses', 'folios', 'suppliers'];
    const newData = groupData.map((element, index) => {
      const nextLevel = levels[levelId + 1];
      if (nextLevel in element && element.showChildren) {
        const array = this.getFormattedData(element.subRows, levelId + 1);
        return [[groupData[index]], ...array];
      } if (nextLevel in element && !element.showChildren) {
        return element && element.subRows ? [element] : element;
      }
      return element && element.subRows ? [element, ...element.subRows] : element;
    });
    return newData;
  }

  render() {
    // const rows = this.generateRows({ saleshouses: this.props.forecastSpendData });
    const rows = this.getFormattedData(this.generateGroupData({ saleshouses: this.props.forecastSpendData })).flat(Infinity);
    return (
      <div id="PivotTable">

        <div id="table-container">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="supplier-cell header-row">
                  Supplier
                </Table.HeaderCell>
                { MONTHS.map(month => (
                  <Table.HeaderCell
                    key={`month${month}`}
                    className="month-col"
                    colSpan="3"
                  >
                    { month }
                    {' '}
                    {this.props.finalisedMonthsData[month].finalized !== true && (
                      <FinaliseMonthCheckbox
                        month={month}
                        onChangeCheckbox={this.updateMonthFinaliseStatus}
                      />
                    )}
                    {this.props.finalisedMonthsData[month].finalized === true && (
                      <>
                        <p className="finalised">
                          (Complete)
                        </p>
                        <ReopenFinalizedMonth
                          month={month}
                          onClick={this.handleReopenFinalizedMonthClick}
                        />
                      </>
                    )}
                  </Table.HeaderCell>
                )) }
                <Table.HeaderCell className="yearly-total-header" colSpan="2">YEARLY TOTAL</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell className="supplier-cell header-two">
                  { /* Search
                  <Icon name="search" /> */ }
                </Table.Cell>
                { MONTHS.map(month => [
                  <Table.Cell key={`liveForecastHeader${month}`} className="live-forecast-cell header-two">Live Forecast (£)</Table.Cell>,
                  <Table.Cell key={`actualsHeader${month}`} className="actuals-cell header-two">Actuals (£)</Table.Cell>,
                  <Table.Cell key={`vsForecastHeader${month}`} className="vs-forecast-cell header-two">vs. Forecast</Table.Cell>
                ]) }
                <Table.Cell key="yearly-total-forecasts" className="actuals-cell header-two">Forecast (£)</Table.Cell>
                <Table.Cell key="yearly-total-actuals" className="actuals-cell header-two">Actuals-YTD (£)</Table.Cell>

              </Table.Row>
              { rows.map(supplier => (
                <PivotTableRow
                  key={`${supplier.level}${supplier.SaleshouseId}${supplier.FolioId}${supplier.SupplierId}`}
                  supplier={{ ...supplier }}
                  orderId={supplier.orderId}
                  showChildren={supplier.showChildren}
                  getDeeperData={this.getDeeperData}
                  handleLiveForecastChange={this.editLiveForecast}
                  setInputRefToFocus={this.setInputRefToFocus}
                  inputRefToFocus={this.state.inputRefToFocus}
                />
              )) }
              <Table.Row>
                <Table.Cell
                  className="monthly-total-header padding-Saleshouse"
                >
                  MONTHLY TOTALS
                </Table.Cell>
                { MONTHS.map(month => (
                  <Fragment
                    key={`${month}-totals`}
                  >
                    <Table.Cell
                      key={`${month}-forecast`}
                      className="monthly-total-cell"
                    >
                      {this.getMonthForecast(month)}
                    </Table.Cell>
                    <Table.Cell
                      key={`${month}-actual`}
                      className="monthly-total-cell"
                    >
                      {this.getMonthActual(month)}
                    </Table.Cell>
                    <Table.Cell
                      key={`${month}-vs`}
                      className="monthly-total-cell"
                    >
                    </Table.Cell>
                  </Fragment>
                )) }
                <Table.Cell
                  key="yearForecasts"
                  className="monthly-total-cell"
                >
                  {this.getYearTotal(FORECASTS)}
                </Table.Cell>
                <Table.Cell
                  key="yearActuals"
                  className="monthly-total-cell"
                >
                  {this.getYearTotal(ACTUALS)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <Modal size="small" className="warningModal" open={this.state.modalForecastLevel.show}>
          <Header>
            <Icon name="warning sign" size="small" />
            Caution
          </Header>
          <Modal.Content>
            <p>
              You have already entered a figure at
              {' '}
              {this.state.modalForecastLevel.forecastLevel}
              {' '}
              level. If you would like to enter figures at
              {' '}
              {this.state.modalForecastLevel.editLevel}
              {' '}
              level then the
              {' '}
              {this.state.modalForecastLevel.forecastLevel}
              {' '}
              level figure will be cleared. Do you want to proceed?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              onClick={() => this.cancelEdit(...this.state.modalForecastLevel.changeLevelVariables)}
            >
              Keep
              {' '}
              {this.state.modalForecastLevel.forecastLevel}
              {' '}
              Figures
            </Button>
            <Button
              basic
              onClick={
                () => this.changeForecastLevel(
                  ...this.state.modalForecastLevel.changeLevelVariables
                )
              }
            >
              Add
              {' '}
              {this.state.modalForecastLevel.editLevel}
              {' '}
              Figures
            </Button>
          </Modal.Actions>
        </Modal>

        <OMGModal
          isOpen={this.state.showReopenFinalizedMonthModal}
          message="This month has already been finalised. Reopening it will allow you to amend the figures and re-finalise the month. However, be aware that reports may already have been created based on this finalised month."
          heading="Reopen Month"
          modalType="warning"
          buttons={[
            {
              name: 'Cancel and keep month closed',
              callback: this.toggleReopenFinalizedMonthModalDisplay
            },
            {
              name: 'Reopen this month',
              callback: this.reopenFinalisedMonth,
              primary: true
            }
          ]}
          toggleDisplay={this.toggleReopenFinalizedMonthModalDisplay}
        />

      </div>
    );
  }
}

PivotTable.propTypes = {
  forecastSpendData: PropTypes.objectOf(PropTypes.object).isRequired,
  // saveSalesHouseForecastSpendData: PropTypes.func.isRequired,
  getClientActualsFilters: PropTypes.func.isRequired,
  finalisedMonthsData: PropTypes.objectOf(PropTypes.object).isRequired,
  saveFolioForecastSpendData: PropTypes.func.isRequired,
  saveSupplierForecastSpendData: PropTypes.func.isRequired,
  toggleCollapseRow: PropTypes.func.isRequired,
  changeForecastValue: PropTypes.func.isRequired,
  changeForecastLevel: PropTypes.func.isRequired,
  changeTempForecastValue: PropTypes.func.isRequired,
  saveYearlyActualsTotal: PropTypes.func.isRequired,
  saveYearlyForecastTotal: PropTypes.func.isRequired,
  yearlySpend: PropTypes.shape({
    actuals: PropTypes.array,
    forecasts: PropTypes.array
  }).isRequired,
  monthlySpend: PropTypes.shape({
    actuals: PropTypes.array,
    forecasts: PropTypes.array
  }).isRequired,
  updateMonthFinalisedStatus: PropTypes.func.isRequired
};

export default connect(
  store => ({
    forecastSpendData: store.forecastSpendData,
    selectedBooking: store.menuFilters.media.selectedBooking,
    yearlySpend: store.totalSpend.yearlySpend,
    monthlySpend: store.totalSpend.monthlySpend,
    finalisedMonthsData: store.finalisedMonths
  }),
  {
    saveFolioForecastSpendData,
    saveSupplierForecastSpendData,
    toggleCollapseRow,
    changeForecastValue,
    changeForecastLevel,
    changeTempForecastValue,
    saveYearlyActualsTotal,
    saveYearlyForecastTotal,
    updateMonthFinalisedStatus
  }
)(PivotTable);

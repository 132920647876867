import { MONTHS } from '../constants';
import saleshouseOrder from '../utils/saleshouseOrder';
import folioOrder from '../utils/folioOrder';
import supplierOrder from '../utils/supplierOrder';

/**
 * Helper function to group an array of objects into an object
 *
 * @param {Object} data
 * @param {Object} params other parameters to be provided in return object (level, parent ids)
 *
 * @returns {Object} as decribed below:
 * {
 *    name: {String},
 *    level: {String}
 *    showChildren: {Boolean},
 *    salesHouseId: {Integer},
 *    // other parent ids if they exist: folioId: {Integer}, supplierId: {Integer}
 *    January: {
 *      actual: {Number String},
 *      forecast: {Number String},
 *      forecastLevel: {String}
 *    }
 *    February: // etc.
 * }
 */

const orderData = { Saleshouse: saleshouseOrder.Saleshouse, Folio: folioOrder.Folio, Supplier: supplierOrder.Supplier };

export default function processForecastSpendData(data, params) {
  if (!data) return {};
  // group data into object of saleshouse objects
  const groupedData = data.reduce((grouped, current) => {
    const key = current.sales_house_id || current.folio_id || current.supplier_id;
    const month = MONTHS[current.month - 1];
    if (!grouped[key]) {
      const name = current.sales_house_name || current.folio_name || current.supplier_name;
      const selectedObject = orderData[params.level].find(x => x.name === name);
      grouped[key] = {
        // id: current.sales_house_id || current.folio_id,
        name: current.sales_house_name || current.folio_name || current.supplier_name,
        showChildren: false,
        orderId: selectedObject ? selectedObject.order_id : null,
        ...params
      };
      grouped[key][`${params.level}Id`] = current.sales_house_id || current.folio_id || current.supplier_id;
    }

    if (!grouped[key][month]) {
      grouped[key][month] = {
        actual: null,
        forecast: null,
        forecastLevel: null
      };
    }

    // this is to get actual figures
    if (current.total) {
      grouped[key][month].actual = current.total;
    }
    if (current.forecast) {
      grouped[key][month].forecast = current.forecast;
    }
    if (current.forecast_level) {
      grouped[key][month].forecastLevel = current.forecast_level;
    }

    return grouped;
  }, {});
  return groupedData;
}

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid, Header, Segment
} from 'semantic-ui-react';
import {
  saveSummaryFiltersData,
  changeDealType,
  changeTimePeriod,
  changeMonth,
  toggleZeroForecastClients
} from '../../actions/summaryFiltersActions';
import DealTypeFilter from '../DealTypeFilter/DealTypeFilter';
import ZeroForecastsFilter from '../ZeroForecastsFilter/ZeroForecastsFilter';
import TimePeriodFilter from '../TimePeriodFilter/TimePeriodFilter';
import MonthFilter from '../MonthFilter/MonthFilter';
import BookingCategoryFilter from '../BookingCategoryFilter/BookingCategoryFilter';
import YearFilter from '../YearFilter/YearFilter';
import SummaryFiltersAPI from '../../api/SummaryFiltersAPI';
import makeDropdownOptions from '../../lib/makeDropdownOptions';
import {
  STANDARD_GROUP_SHARE
} from '../../constants';


export class SummaryFilters extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeDealType = this.onChangeDealType.bind(this);
    this.onChangeTimePeriod = this.onChangeTimePeriod.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeZeroForecasts = this.onChangeZeroForecasts.bind(this);
  }

  componentDidMount() {
    this.getSummaryFiltersOptions();
  }

  onChangeDealType(dealType) {
    this.props.changeDealType(dealType);
  }

  onChangeTimePeriod(event) {
    const selectedTimePeriod = event.target.id;
    this.props.changeTimePeriod(selectedTimePeriod);
  }

  onChangeMonth(month) {
    this.props.changeMonth(month);
  }

  onChangeZeroForecasts(value) {
    const isChecked = value ? 1 : 0;
    this.props.toggleZeroForecastClients(isChecked);
  }

  async getSummaryFiltersOptions() {
    // load options if they haven't already been loaded
    const shouldLoadOptions = !this.props.summaryFilters.dealTypes.data.length;

    if (shouldLoadOptions) {
      try {
        const summaryFiltersOptions = await SummaryFiltersAPI.getDealTypes();
        this.props.saveSummaryFiltersData(summaryFiltersOptions);

        // select "STANDARD GROUP SHARE" deal type by default
        const isStandardGroupShare = element => element === STANDARD_GROUP_SHARE;

        const standardGroupShareIndex = summaryFiltersOptions
          .deal_types
          .data
          .findIndex(isStandardGroupShare);

        this.props.changeDealType(summaryFiltersOptions.deal_types.data[standardGroupShareIndex]);

        // select current month by default
        const currentDate = new Date();
        this.props.changeMonth(currentDate.getMonth() + 1);
      } catch (e) { console.log(e); }
    }
  }

  render() {
    return (
      <Segment inverted>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h3" inverted>Filter Data</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={5}>
            <Grid.Column width={4}>
              <DealTypeFilter
                dealTypeOptions={makeDropdownOptions(this.props.summaryFilters.dealTypes.data)}
                onChangeDealType={this.onChangeDealType}
                selectedDealType={this.props.summaryFilters.dealTypes.selectedDealType}
              />
              <ZeroForecastsFilter
                onChangeZeroForecasts={this.onChangeZeroForecasts}
                showZeroForecastClients={this.props.summaryFilters.showZeroForecastClients}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <BookingCategoryFilter />
            </Grid.Column>

            <Grid.Column width={4}>
              <TimePeriodFilter
                timePeriodData={this.props.summaryFilters.timePeriods}
                onChangeTimePeriod={this.onChangeTimePeriod}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <MonthFilter
                monthData={this.props.summaryFilters.months}
                onChangeMonth={this.onChangeMonth}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <YearFilter
                showLabel={false}
                fluid
                triggerTableRefresh={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column width={4}>

            </Grid.Column>

            <Grid.Column width={4}>

            </Grid.Column>

            <Grid.Column width={5}>

            </Grid.Column>

            <Grid.Column width={3}>

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

SummaryFilters.propTypes = {
  summaryFilters: PropTypes.shape({
    dealTypes: PropTypes.object,
    timePeriods: PropTypes.object,
    months: PropTypes.object,
    showZeroForecastClients: PropTypes.number
  }).isRequired,
  saveSummaryFiltersData: PropTypes.func.isRequired,
  changeDealType: PropTypes.func.isRequired,
  changeTimePeriod: PropTypes.func.isRequired,
  changeMonth: PropTypes.func.isRequired,
  toggleZeroForecastClients: PropTypes.func.isRequired
};

export default connect(
  store => ({
    summaryFilters: store.summaryFilters
  }),
  {
    saveSummaryFiltersData,
    changeDealType,
    changeTimePeriod,
    changeMonth,
    toggleZeroForecastClients
  }
)(SummaryFilters);

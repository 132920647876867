import { MONTHS } from '../constants';

/**
 * Helper function to group an array of objects
 * (containing finalised months)  into an object. The API only returns
 * months that are finalised, this method will add unfinalised months to the
 * returned object.
 *
 * @param {Object} data
 * @param {Object} params other parameters to be provided in return object (level, parent ids)
 *
 * @returns {Object} as decribed below:
 * {
 *    January: {
 *      year: 2021
 *      month: 1
 *      client: 22
 *      finalized: true
 *      media: 13
 *      booking: 11
 *    }
 * }
 */

export default function processFinalisedMonthsData(data, params) {
  const groupedData = MONTHS.reduce((grouped, month, i) => {
    // get object for the month from data
    const monthIndex = i + 1;
    let monthData = data.find(k => k.month === monthIndex);

    if (monthData === undefined) {
      monthData = {
        ...params,
        month: monthIndex,
        finalized: false
      };
    }

    if (!grouped[month]) {
      grouped[month] = monthData;
    }
    return grouped;
  }, {});

  return groupedData;
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid, Header, Container, Button, Dropdown, Form, Loader, Checkbox, Message, Icon
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import UsersAPI from '../../api/UsersAPI';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import './UserManagement.css';
import isUserAdmin from '../../lib/isAdminUser';

export class UserDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      assignedClients: [],
      allClients: [],
      selectedClients: [],
      agencyOptions: [],
      roleOptions: [],
      selectedRole: null,
      selectedAgency: null,
      userRolesLoading: false,
      userActive: true,
      fetching: false,
      apiError: false,
      apiSuccess: false,
      errorMessage: '',
      updatingUserDetails: false,
      clientsLoading: false,
    };

    this.fetchuserDetails = this.fetchuserDetails.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.agencySelected = this.agencySelected.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.userClientsSelected = this.userClientsSelected.bind(this);
    this.selectRole = this.selectRole.bind(this);
  }

  componentDidMount() {
    this.fetchUserRoles();
    const { userId } = this.props.match.params;
    const { agencyId } = this.props.match.params;
    if (userId && agencyId && isUserAdmin(localStorage.iosRole)) {
      this.fetchuserDetails(agencyId, userId);
    }
  }

  getClientsAsString() {
    if (!this.state.assignedClients) {
      return 'No clients';
    }
    const userClients = this.state.assignedClients.map(client => client.text);
    return userClients.toString();
  }

  async fetchuserDetails(agencyId, userId) {
    try {
      this.setState({ fetching: true });
      const user = await UsersAPI.getUserDetails(agencyId, userId);

      const assignedClients = user.ios_buyer.map(ub => ({
        key: ub.id,
        text: ub.name,
        value: ub.id
      }));

      const clientOptions = user.available_buyers.map(ab => ({
        key: ab.id,
        text: ab.name,
        value: ab.id
      }));

      let allClients = [];
      allClients = [...assignedClients, ...clientOptions];

      const agencyOptions = user.available_agencies.map(ag => ({
        key: ag.id,
        text: ag.name,
        value: ag.id
      }));
      this.setState({
        fetching: false,
        user,
        assignedClients,
        allClients,
        userActive: user.is_active,
        selectedRole: user.role_id,
        agencyOptions,
        selectedAgency: user.agency_id,
        selectedClients: assignedClients.map(ac => ac.key)
      });
    } catch (error) {
      this.setState({ apiError: true, errorMessage: 'User Not found' });
    }
  }

  async fetchUserRoles() {
    try {
      this.setState({ userRolesLoading: true });
      let roleOptions = await UsersAPI.getUserRoles();
      const loginUserRole = roleOptions.find(x => x.name === localStorage.iosRole);
      const loginUserRoleId = loginUserRole ? loginUserRole.id : null;
      roleOptions = roleOptions.filter(x => x.id >= loginUserRoleId).map(role => ({
        key: role.id,
        text: role.name,
        value: role.id
      }));
      this.setState({ userRolesLoading: false, roleOptions });
    } catch (error) {
      this.setState({ userRolesLoading: false, errorMessage: 'Error fetching user access levels' });
    }
  }

  checkboxChangeHandler() {
    this.setState({ userActive: !this.state.userActive });
  }

  agencySelected(e, { value }) {
    this.setState({ selectedAgency: value, apiError: false });
    this.fetchAgencyBuyers(value);
  }

  selectRole(e, { value }) {
    this.setState({ selectedRole: value });
  }

  userClientsSelected(e, { value }) {
    return this.setState({ selectedClients: value });
  }

  async submitChanges() {
    this.setState({ apiError: false, apiSuccess: false, updatingUserDetails: true });
    const userDetails = {
      user_id: this.state.user.id,
      user_active: this.state.userActive,
      user_ios_buyers: this.state.selectedClients,
      user_agency: this.state.selectedAgency,
      user_role_id: this.state.selectedRole
    };

    try {
      // eslint-disable-next-line no-unused-vars
      const userDetailsUpdated = await UsersAPI.updateUser(userDetails);
      // eslint-disable-next-line no-unused-vars
      const fetchUsersAgain = await this.fetchuserDetails(
        this.state.selectedAgency, this.state.user.id
      );
      this.setState({ apiSuccess: true, updatingUserDetails: false });
    } catch (error) {
      this.setState({ apiError: true, errorMessage: 'Error updating user details', updatingUserDetails: false });
    }
  }

  async fetchAgencyBuyers(agencyId) {
    if (!agencyId) {
      return false;
    }
    this.setState({ clientsLoading: true, allClients: [], selectedClients: [] });
    try {
      let allClients = await UsersAPI.getAgencyBuyers(agencyId);
      allClients = allClients.map(ab => ({
        key: ab.id,
        text: ab.name,
        value: ab.id
      }));
      this.setState({ allClients, clientsLoading: false });
    } catch (error) {
      this.setState({ clientsLoading: false, apiError: true, errorMessage: 'Error fetching IOS Advertisers' });
    }

    return true;
  }

  render() {
    const {
      user, allClients, userActive, agencyOptions, selectedAgency, selectedClients, errorMessage, updatingUserDetails,
      roleOptions, selectedRole, userRolesLoading, clientsLoading
    } = this.state;

    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />
        <div id="AdminPage">
          <div className="title-breadcrumb">
            <Header as="h1">Investment Operating System</Header>
            {/* <OMGBreadcrumb /> */}
          </div>

          <div id="container">
            <div id="userDetail">
              <Grid>
                <Grid.Column>
                  <Link to="/admin">
                    <Button className="top-back-btn" basic>
                      <Icon name="arrow left" />
                    Back
                    </Button>
                  </Link>
                  <hr />
                  <Loader active={this.state.fetching} inline="centered" />
                  {
                    !this.state.fetching && (
                      <Grid columns={2}>
                        <Grid.Column>
                          <h2>
                            <b> User Details </b>
                          </h2>
                          <p>Add or update the details of this user.</p>
                          <h3 className="user-email">{user && user.email ? user.email : ''}</h3>
                          <h2 className="user-agency-ln">
                            <b>
                              Agency:
                              {' '}
                              {user && user.agency_name ? user.agency_name : ''}
                            </b>
                          </h2>
                          <h5 className="user-label">Assigned Clients </h5>
                          <p>{this.getClientsAsString()}</p>
                          <h5 className="user-label">Edit your clients</h5>
                          <Dropdown
                            placeholder="Clients"
                            fluid
                            multiple
                            selection
                            options={allClients}
                            value={selectedClients}
                            loading={clientsLoading}
                            onChange={this.userClientsSelected}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Container textAlign="justified" fluid className="user-agency">
                            <h3>Administrator Options</h3>
                            <h5 className="user-label">Account Status</h5>
                            <div className="status-switch">
                              <Checkbox toggle checked={userActive} onChange={this.checkboxChangeHandler} />
                              <span className="status-label">{userActive ? 'Active' : 'Inactive'}</span>
                            </div>
                            <Form.Field className="admin-form-field">
                              <h5 className="user-label">Works for Agency</h5>
                              <Dropdown
                                placeholder="Select Agency"
                                fluid
                                selection
                                options={agencyOptions}
                                value={selectedAgency}
                                onChange={this.agencySelected}
                              />
                            </Form.Field>
                            <Form.Field className="admin-form-field">
                              <h5 className="user-label">Access Level</h5>
                              <Dropdown
                                placeholder="Access Level"
                                fluid
                                selection
                                options={roleOptions}
                                value={selectedRole}
                                onChange={this.selectRole}
                                loading={userRolesLoading}
                              />
                            </Form.Field>
                          </Container>
                        </Grid.Column>
                      </Grid>
                    )
                  }

                  {!this.state.fetching && (
                    <Button
                      primary
                      className="user-submit-btn"
                      onClick={this.submitChanges}
                      loading={updatingUserDetails}
                      disabled={updatingUserDetails}
                    >
                      Save changes
                    </Button>
                  )}
                  {
                    !this.state.fetching && (
                      <Link to="/admin">
                        <Button className="user-submit-btn cancel-btn">
                        Cancel
                        </Button>
                      </Link>
                    )
                  }
                  {this.state.apiError && (<Message color="red">{errorMessage}</Message>)}
                  {this.state.apiSuccess && (<Message color="green">User details updated</Message>)}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserDetailPage.propTypes = {
  email: PropTypes.string.isRequired
};

export default connect(
  store => ({
    email: localStorage.getItem('iosEmail')
  }),
  {}
)(UserDetailPage);

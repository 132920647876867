import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form, Container, Header, Button, Message, Loader, Dropdown
} from 'semantic-ui-react';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import ForecastMatchActualsAPI from '../../api/ForecastMatchActualsAPI';



export class ForecastMatchActualsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iosBuyers: [],
      years: [],
      months: [],
      selectedIosBuyers: [],
      selectedYear: null,
      selectedMonth: null,
      errorMessage: null,
      successMessage: null,
      menuLoading: false,
      matchingForecasts: false
    };
    // this.handleFileChange = this.handleFileChange.bind(this);
    // this.uploadForecast = this.uploadForecast.bind(this);
    this.fetchForecastsMatchActualsMenu = this.fetchForecastsMatchActualsMenu.bind(this);
  }

  /**
   * Fetch the list of agency/id to be displayed in the form select when the component mounts.
   */
  componentDidMount() {
    this.fetchForecastsMatchActualsMenu();
  }

  /**
   * Fetch the list of agency/id to be displayed in the form select when the component updates.
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.iosBuyers.length !== this.state.iosBuyers.length) {
      if (this.state.iosBuyers.length < 1) {
        this.fetchForecastsMatchActualsMenu();
      }
    }
  }

  async fetchForecastsMatchActualsMenu() {
    try {
      if (!this.state.iosBuyers.length) {
        this.setState({ menuLoading: true });
        const iosBuyerData = await ForecastMatchActualsAPI.forecastMatchWithActualsMenu();
        let iosBuyers = iosBuyerData.buyers;
        let {years} = iosBuyerData;
        years = years.map(y => {
          return {
            key: `${y}`,
            text: y,
            value: y
          };
        });

        let months = [];

        for (let step = 1; step <= 12; step++) {
          months.push({
            key: `${step}`,
            text: step,
            value: step
          });
        }

        iosBuyers = iosBuyers.map(d => {
          d.key = `${d.name}-${d.id}`;
          d.text = d.name;
          d.value = d.id;
          return d;
        });
        this.setState({
          iosBuyers, errorMessage: null, menuLoading: false, years, months
        });
      }
    } catch (err) {
      this.setState({ iosBuyers: [], menuLoading: false, errorMessage: `Error fetching Menu:${err}` });
    }
  }

  async matchForecastWithActuals() {
    this.setState({
      errorMessage: null,
      successMessage: null,
      matchingForecasts: true
    });
    try {
      if (!this.state.selectedIosBuyers.length) {
        this.setState({ errorMessage: 'IOS Buyers not selected.', matchingForecasts: false });
        return null;
      }
      if (!this.state.selectedYear) {
        this.setState({ errorMessage: 'Year not selected.', matchingForecasts: false });
        return null;
      }
      if (!this.state.selectedMonth) {
        this.setState({ errorMessage: 'Month not selected.', matchingForecasts: false });
        return null;
      }

      const formData = new FormData();
      formData.append('buyers', this.state.selectedIosBuyers);
      formData.append('year', this.state.selectedYear);
      formData.append('month', this.state.selectedMonth);

      const matchForecastsCall = await ForecastMatchActualsAPI.matchForecastsWithActuals(formData);

      this.setState({
        matchingForecasts: false,
        successMessage: JSON.stringify(matchForecastsCall.data.msg)
      });
    } catch (error) {
      console.log(error);
      this.setState({ errorMessage: `${JSON.stringify(error.response.data)}`, matchingForecasts: false });
    }
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />
        <Container>
          <br />
          <Header as="h2">Forecast Match Actuals</Header>
          <br />
          <Form.Group widths="equal" key="iosBuyerFormGroup">
            <span>Select IOS Buyers</span>
            <br />
            <Dropdown
              key="iosBuyer"
              placeholder="Select IOS Buyers"
              selection
              multiple
              options={this.state.iosBuyers}
              aria-label="Select IOS Buyers"
              onChange={(e, { value }) => this.setState({ selectedIosBuyers: value })}
              loading={this.state.menuLoading}
            />
            <br />
          </Form.Group>
          <br />

          <Form.Group widths="equal" key="yearFormGroup">
            <span>Select Year</span>
            <br />
            <Dropdown
              key="year"
              placeholder="Select Year"
              selection
              options={this.state.years}
              aria-label="Select Year"
              onChange={(e, { value }) => this.setState({ selectedYear: value })}
              loading={this.state.menuLoading}
            />
            <br />
          </Form.Group>
          <br />

          <Form.Group widths="equal" key="monthFormGroup">
            <span>Select Month</span>
            <br />
            <Dropdown
              key="year"
              placeholder="Select Month"
              selection
              options={this.state.months}
              aria-label="Select Month"
              onChange={(e, { value }) => this.setState({ selectedMonth: value })}
              loading={this.state.menuLoading}
            />
            <br />
          </Form.Group>
          <br />

          <br />
          <Button
            primary
            onClick={e => this.matchForecastWithActuals(e)}
            className="upload-button"
            disabled={this.state.matchingForecasts}
          >
            Match Forecasts &nbsp; &nbsp;
            { this.state.matchingForecasts && (<Loader active inline inverted size="tiny" />) }
          </Button>
          {this.state.errorMessage && (
            <Message negative>
              <Message.Header>{this.state.errorMessage}</Message.Header>
            </Message>
          )}
          {this.state.successMessage && (
            <Message positive>
              <Message.Header>{this.state.successMessage}</Message.Header>
            </Message>
          )}
        </Container>
      </div>
    );
  }
}
ForecastMatchActualsPage.propTypes = {
  email: PropTypes.string.isRequired
};

export default connect(
  // eslint-disable-next-line no-unused-vars
  store => ({
    email: localStorage.getItem('iosEmail')
  }),
  null
)(ForecastMatchActualsPage);

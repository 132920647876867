import axios from 'axios';

const ForecastAPI = {
  uploadForecast: async forecastData => {
    const uploadResult = await axios.post(
      process.env.REACT_APP_FORECAST_UPLOAD_URL,
      forecastData,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken'),
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return uploadResult;
  }
};

export default ForecastAPI;

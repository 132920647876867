import { SALESHOUSE_LEVEL, FOLIO_LEVEL, SUPPLIER_LEVEL } from '../constants';

const levelIds = {};
levelIds[SALESHOUSE_LEVEL] = 'sales_house_id';
levelIds[FOLIO_LEVEL] = 'folio_id';
levelIds[SUPPLIER_LEVEL] = 'supplier_id';

/**
 * Check if yearly actuals is already saved.
 * Replace old yearly actuals with the new ones.
 * @param {*} oldActuals
 * @param {*} newActuals
 * @param {*} level
 */
export function processYearlyActuals(oldActuals, newActuals, level) {
  try {
    if (level === SALESHOUSE_LEVEL) {
      return newActuals;
    }
    const existingIds = newActuals.map(nData => nData[levelIds[level]]);
    const newData = oldActuals.filter(
      d => !existingIds.includes(d[levelIds[level]])
    );
    return [...newData, ...newActuals];
  } catch (error) {
    return [];
  }
}

/**
 * Replace old yearly forecasts with new data.
 * @param {*} oldForecasts
 * @param {*} newForecasts
 * @param {*} level
 */
export function processYearlyForecasts(oldForecasts, newForecasts, level) {
  try {
    if (level === SALESHOUSE_LEVEL) {
      return newForecasts;
    }
    const existingIds = newForecasts.map(nData => nData[levelIds[level]]);

    const newData = oldForecasts.filter(
      d => !existingIds.includes(d[levelIds[level]])
    );

    return [...newData, ...newForecasts];
  } catch (error) {
    return [];
  }
}

import { combineReducers } from 'redux';
import user from './reducers/userReducer';
import breadcrumb from './reducers/breadcrumbReducer';
import menuFilters from './reducers/menuFiltersReducer';
import forecastSpendData from './reducers/forecastSpendDataReducer';
import totalSpend from './reducers/totalSpendReducer';
import finalisedMonths from './reducers/finalisedMonthsReducer';
import summaryFilters from './reducers/summaryFiltersReducer';
import authInfo from './reducers/authInfoReducer';
import adminPageInfo from './reducers/adminPageReducer';

const rootReducer = combineReducers({
  user,
  breadcrumb,
  menuFilters,
  forecastSpendData,
  totalSpend,
  finalisedMonths,
  summaryFilters,
  authInfo,
  adminPageInfo
});

export default rootReducer;

import {
  SAVE_SALES_HOUSE_FORECAST_SPEND_DATA,
  SAVE_FOLIO_FORECAST_SPEND_DATA,
  SAVE_SUPPLIER_HOUSE_FORECAST_SPEND_DATA,
  TOGGLE_COLLAPSE_ROW,
  CHANGE_FORECAST_VALUE,
  CHANGE_FORECAST_LEVEL,
  CHANGE_TEMP_FORECAST_LEVEL
} from '../types/forecastSpendDataTypes';

import {
  SALESHOUSE_LEVEL,
  FOLIO_LEVEL,
  SUPPLIER_LEVEL
} from '../constants';

const initialState = {};

export default function forecastSpendData(state = initialState, action) {
  switch (action.type) {
    case SAVE_SALES_HOUSE_FORECAST_SPEND_DATA:
      return action.data;

    case SAVE_FOLIO_FORECAST_SPEND_DATA:
      state[action.data.salesHouseId].folios = action.data.folios;
      return { ...state };

    case SAVE_SUPPLIER_HOUSE_FORECAST_SPEND_DATA:
      state[action.data.salesHouseId].folios[action.data.folioId].suppliers = action.data.suppliers;
      return { ...state };

    case TOGGLE_COLLAPSE_ROW:
      switch (action.data.level) {
        case SALESHOUSE_LEVEL:
          state[action.data.salesHouseId].showChildren = !state[action.data.salesHouseId].showChildren;
          return { ...state };

        case FOLIO_LEVEL:
          state[action.data.salesHouseId].folios[action.data.folioId].showChildren = !state[action.data.salesHouseId].folios[action.data.folioId].showChildren;
          return { ...state };

        default:
          return { ...state };
      }

    case CHANGE_FORECAST_VALUE:
      switch (action.data.level) {
        case SALESHOUSE_LEVEL:
          state[action.data.saleshouseId][action.data.month] = {
            ...state[action.data.saleshouseId][action.data.month],
            forecast: action.data.forecast,
            changed: true
          };
          return { ...state };

        case FOLIO_LEVEL:
          state[action.data.saleshouseId].folios[action.data.folioId][action.data.month] = {
            ...state[action.data.saleshouseId].folios[action.data.folioId][action.data.month],
            forecast: action.data.forecast,
            changed: true
          };
          return { ...state };

        case SUPPLIER_LEVEL:
          state[action.data.saleshouseId].folios[action.data.folioId].suppliers[action.data.supplierId][action.data.month] = {
            ...state[action.data.saleshouseId].folios[action.data.folioId].suppliers[action.data.supplierId][action.data.month],
            forecast: action.data.forecast,
            changed: true
          };
          return { ...state };

        default:
          return { ...state };
      }

    case CHANGE_FORECAST_LEVEL:
      switch (action.data.level) {
        case SALESHOUSE_LEVEL:
          state[action.data.saleshouseId][action.data.month] = {
            ...state[action.data.saleshouseId][action.data.month],
            forecastLevel: action.data.forecastLevel,
            changed: true
          };
          return { ...state };

        case FOLIO_LEVEL:
          state[action.data.saleshouseId].folios[action.data.folioId][action.data.month] = {
            ...state[action.data.saleshouseId].folios[action.data.folioId][action.data.month],
            forecastLevel: action.data.forecastLevel,
            changed: true
          };
          return { ...state };

        case SUPPLIER_LEVEL:
          state[action.data.saleshouseId].folios[action.data.folioId].suppliers[action.data.supplierId][action.data.month] = {
            ...state[action.data.saleshouseId].folios[action.data.folioId].suppliers[action.data.supplierId][action.data.month],
            forecastLevel: action.data.forecastLevel,
            changed: true
          };
          return { ...state };

        default:
          return { ...state };
      }

    case CHANGE_TEMP_FORECAST_LEVEL:
      switch (action.data.level) {
        case SALESHOUSE_LEVEL:
          state[action.data.saleshouseId][action.data.month] = {
            ...state[action.data.saleshouseId][action.data.month],
            changed: action.data.tempForecast
          };
          return { ...state };

        case FOLIO_LEVEL:
          state[action.data.saleshouseId].folios[action.data.folioId][action.data.month] = {
            ...state[action.data.saleshouseId].folios[action.data.folioId][action.data.month],
            changed: action.data.tempForecast
          };
          return { ...state };

        case SUPPLIER_LEVEL:
          state[action.data.saleshouseId].folios[action.data.folioId].suppliers[action.data.supplierId][action.data.month] = {
            ...state[action.data.saleshouseId].folios[action.data.folioId].suppliers[action.data.supplierId][action.data.month],
            changed: action.data.tempForecast
          };
          return { ...state };

        default:
          return { ...state };
      }

    default:
      return state;
  }
}

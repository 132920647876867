import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Header } from 'semantic-ui-react';
import OMGNavigation from '../OMGNavigation/OMGNavigation';

export class Error404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />
        <Container>
          <br />
          <Header as="h2">Error 404, Page not found</Header>
        </Container>
      </div>
    );
  }
}

Error404.defaultProps = {
  email: null
};

Error404.propTypes = {
  email: PropTypes.string
};

export default connect(
  // eslint-disable-next-line no-unused-vars
  store => ({
    email: localStorage.getItem('iosEmail')
  }),
  null
)(Error404);

import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function DealTypeFilter(props) {
  return (
    <>
      <span id="deal-type-label">Deal Type</span>
      <Dropdown
        fluid
        selection
        options={props.dealTypeOptions}
        aria-labelledby="deal-type-label"
        value={props.selectedDealType}
        onChange={(e, { value }) => props.onChangeDealType(value)}
        loading={!props.dealTypeOptions.length}
        placeholder="Select a deal type"
      />
    </>
  );
}

DealTypeFilter.propTypes = {
  dealTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeDealType: PropTypes.func.isRequired,
  selectedDealType: PropTypes.string
};

DealTypeFilter.defaultProps = {
  selectedDealType: null
};

export default DealTypeFilter;

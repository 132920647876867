import axios from 'axios';

const PivotTableAPI = {

  /**
   * Fetch client actuals data AND forecasts data.
   * @param {Object} filters
   */
  getClientActuals: async filters => {
    const clientActuals = await axios.post(process.env.REACT_APP_CLIENT_ACTUALS_URL,
      filters,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      });
    return {
      monthlyData: [
        ...clientActuals.data.actuals,
        ...clientActuals.data.forecasts
      ],
      yearlyData: {
        actuals: clientActuals.data.yearly_actuals,
        forecasts: clientActuals.data.yearly_forecasts
      },
      months: [...clientActuals.data.months]
    };
  },

  saveForecasts: async data => {
    const response = await axios.post(process.env.REACT_APP_CLIENT_FORECASTS_URL,
      data,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      });
    return response.data;
  }
};

export default PivotTableAPI;

import types from '../types/authTypes';

const initialState = {};

export default function user(state = initialState, action) {
  switch (action.type) {
    case types.USER_LOGGED_IN:
      return action.data;

    case types.USER_LOGGED_OUT:
      return {};

    default:
      return state;
  }
}

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];
export const SALESHOUSE_LEVEL = 'Saleshouse';
export const FOLIO_LEVEL = 'Folio';
export const SUPPLIER_LEVEL = 'Supplier';
export const FOLIO_SUPPLIER_LEVEL = 'Folio/Supplier';
export const ACTUALS = 'actuals';
export const FORECASTS = 'forecasts';
export const STANDARD_GROUP_SHARE = 'STANDARD GROUP SHARE';
export const TIME_PERIODS = ['month', 'year_to_date', 'year_end'];

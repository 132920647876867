import {
  SAVE_MENU_FILTERS_DATA,
  CHANGE_AGENCY_TEAM,
  CHANGE_CLIENT,
  CHANGE_BRAND,
  CHANGE_MEDIA,
  CHANGE_BOOKING,
  CHANGE_YEAR
} from '../types/menuFiltersTypes';

const initialState = {
  brands: {
    data: [],
    selectedAgencyTeam: null,
    selectedClient: null,
    selectedBrand: null
  },
  media: {
    data: [],
    selectedMedia: null,
    selectedBooking: null
  },
  years: {
    data: [],
    selectedYear: null
  }
};

export default function menuFilters(state = initialState, action) {
  switch (action.type) {
    case SAVE_MENU_FILTERS_DATA:
      return {
        brands: {
          ...state.brands,
          ...action.data.brands
        },
        media: {
          ...state.media,
          ...action.data.media
        },
        years: {
          ...state.years,
          ...action.data.years
        }
      };

    case CHANGE_AGENCY_TEAM:
      return { ...state, brands: { ...state.brands, selectedAgencyTeam: action.data } };

    case CHANGE_CLIENT:
      return { ...state, brands: { ...state.brands, selectedClient: action.data } };

    case CHANGE_BRAND:
      return { ...state, brands: { ...state.brands, selectedBrand: action.data } };

    case CHANGE_MEDIA:
      return { ...state, media: { ...state.media, selectedMedia: action.data } };

    case CHANGE_BOOKING:
      return { ...state, media: { ...state.media, selectedBooking: action.data } };

    case CHANGE_YEAR:
      return { ...state, years: { ...state.years, selectedYear: action.data } };

    default:
      return state;
  }
}

/**
 * Helper function to correctly parse digits
 * if 0 or '' then don't do anything 
 * 
 * @param {String} value
 * @returns {String} formattedValue
 */
export default function formatNumberValue(value) {
  let formattedValue

  if(typeof value == 'string'){
    value = value.replace(/,/g,'')
  }

  formattedValue = value !== '' ? parseFloat(value) : value

  return formattedValue
}

import {
  SAVE_YEARLY_ACTUALS_TOTALS,
  SAVE_YEARLY_FORECAST_TOTALS,
  SAVE_MONTHLY_ACTUALS_TOTALS,
  SAVE_MONTHLY_FORECAST_TOTALS
} from '../types/totalSpendTypes';

const initialState = {
  yearlySpend: {
    actuals: [],
    forecasts: []
  },
  monthlySpend: {
    actuals: [],
    forecasts: []
  }
};

export default function totalSpend(state = initialState, action) {
  switch (action.type) {
    case SAVE_YEARLY_ACTUALS_TOTALS:
      return {
        ...state,
        yearlySpend: { ...state.yearlySpend, actuals: action.data }
      };
    case SAVE_YEARLY_FORECAST_TOTALS:
      return {
        ...state,
        yearlySpend: { ...state.yearlySpend, forecasts: action.data }
      };
    case SAVE_MONTHLY_ACTUALS_TOTALS:
      return {
        ...state,
        monthlySpend: { ...state.monthlySpend, actuals: action.data }
      };
    case SAVE_MONTHLY_FORECAST_TOTALS:
      return {
        ...state,
        monthlySpend: { ...state.monthlySpend, forecasts: action.data }
      };
    default:
      return state;
  }
}

import types from '../types/breadcrumbTypes';


export const saveBreadcrumbOptions = data => dispatch => {
  dispatch({
    type: types.SAVE_BREADCRUMB_OPTIONS,
    data
  });
};

export const changeBreadcrumbMarket = data => dispatch => {
  dispatch({
    type: types.CHANGE_BREADCRUMB_MARKET,
    data
  });
};

export const changeBreadcrumbParentGroup = data => dispatch => {
  dispatch({
    type: types.CHANGE_BREADCRUMB_PARENT_GROUP,
    data
  });
};

export const changeBreadcrumbAgency = data => dispatch => {
  dispatch({
    type: types.CHANGE_BREADCRUMB_AGENCY,
    data
  });
};

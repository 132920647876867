import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid, Header, Container, Button, Dropdown, Form, Checkbox, Message, Input, Icon
} from 'semantic-ui-react';
import UsersAPI from '../../api/UsersAPI';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import './UserManagement.css';
import isUserAdmin from '../../lib/isAdminUser';

export class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: '',
      allClients: [],
      selectedClients: [],
      clientsLoading: false,
      agencyOptions: [],
      roleOptions: [],
      agencyLoading: false,
      userRolesLoading: false,
      selectedAgency: null,
      selectedRole: null,
      userActive: true,
      apiError: false,
      apiSuccess: false,
      errorMessage: 'Forbidden',
      userCanViewPage: false
    };

    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.agencySelected = this.agencySelected.bind(this);
    this.addUser = this.addUser.bind(this);
    this.userClientsSelected = this.userClientsSelected.bind(this);
    this.emailUpdated = this.emailUpdated.bind(this);
    this.selectRole = this.selectRole.bind(this);
  }

  componentDidMount() {
    this.setState({ userCanViewPage: isUserAdmin(localStorage.iosRole) });
    this.setState({ selectedAgency: this.props.loginUserInfo ? this.props.loginUserInfo.agency_id : null });
    this.fetchUserAgencies();
    this.fetchUserRoles();
    if (this.props.loginUserInfo && this.props.loginUserInfo.agency_id) {
      this.fetchAgencyBuyers(this.props.loginUserInfo.agency_id);
    }
  }

  async fetchUserAgencies() {
    try {
      this.setState({ agencyLoading: true });
      let agencyOptions = await UsersAPI.getUserAgencies();
      agencyOptions = agencyOptions.map(ag => ({
        key: ag.id,
        text: ag.name,
        value: ag.id
      }));
      this.setState({ agencyLoading: false, agencyOptions });
    } catch (error) {
      this.setState({ agencyLoading: false, errorMessage: 'Error fetching Agencies' });
    }
  }

  async fetchUserRoles() {
    try {
      this.setState({ userRolesLoading: true });
      let roleOptions = await UsersAPI.getUserRoles();
      const loginUserRole = roleOptions.find(x => x.name === localStorage.iosRole);
      const loginUserRoleId = loginUserRole ? loginUserRole.id : null;
      roleOptions = roleOptions.filter(x => x.id >= loginUserRoleId).map(role => ({
        key: role.id,
        text: role.name,
        value: role.id
      }));
      this.setState({ userRolesLoading: false, roleOptions });
    } catch (error) {
      this.setState({ userRolesLoading: false, errorMessage: 'Error fetching user access levels' });
    }
  }


  checkboxChangeHandler() {
    this.setState({ userActive: !this.state.userActive });
  }

  async fetchAgencyBuyers(agencyId) {
    if (!agencyId) {
      return false;
    }
    this.setState({ clientsLoading: true, allClients: [], selectedClients: [] });
    try {
      let allClients = await UsersAPI.getAgencyBuyers(agencyId);
      allClients = allClients.map(ab => ({
        key: ab.id,
        text: ab.name,
        value: ab.id
      }));
      this.setState({ allClients, clientsLoading: false });
    } catch (error) {
      this.setState({ clientsLoading: false, apiError: true, errorMessage: 'Error fetching IOS Advertisers' });
    }

    return true;
  }

  agencySelected(e, { value }) {
    this.setState({ selectedAgency: value, apiError: false });
    this.fetchAgencyBuyers(value);
  }

  selectRole(e, { value }) {
    this.setState({ selectedRole: value });
  }

  userClientsSelected(e, { value }) {
    return this.setState({ selectedClients: value });
  }

  emailUpdated(e, { value }) {
    this.setState({ userEmail: value });
  }

  async addUser() {
    // this.setState({ apiError: false, apiSuccess: false, updatingUserDetails: true });
    const userDetails = {
      user_email: this.state.userEmail,
      user_active: this.state.userActive,
      user_ios_buyers: this.state.selectedClients,
      user_agency: this.state.selectedAgency,
      user_role_id: this.state.selectedRole
    };

    if (!userDetails.user_email) {
      this.setState({ apiError: true, errorMessage: 'User email cannot be null' });
      return false;
    }
    if (!userDetails.user_agency) {
      this.setState({ apiError: true, errorMessage: 'User agency cannot be null' });
      return false;
    }
    if (!userDetails.user_role_id) {
      this.setState({ apiError: true, errorMessage: 'User access level cannot be null' });
      return false;
    }
    try {
      this.setState({ apiError: false });
      // eslint-disable-next-line no-unused-vars
      const userCreated = await UsersAPI.createUser(userDetails);
      this.setState({ apiSuccess: true, updatingUserDetails: false });
    } catch (error) {
      if (error.response.status === 400) {
        this.setState({ apiError: true, errorMessage: error.response.data.non_field_errors[0], updatingUserDetails: false });
      } else {
        this.setState({ apiError: true, errorMessage: 'Error creating user', updatingUserDetails: false });
      }
    }
    return true;
  }

  render() {
    const {
      userEmail,
      clientsLoading,
      allClients,
      userActive,
      agencyOptions,
      selectedAgency,
      selectedRole,
      selectedClients,
      errorMessage,
      updatingUserDetails,
      agencyLoading,
      userRolesLoading,
      roleOptions
    } = this.state;

    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />
        <div id="AdminPage">
          <div className="title-breadcrumb">
            <Header as="h1">Investment Operating System</Header>
          </div>

          <div id="container">
            <div id="userDetail">
              <Grid>
                <Grid.Column>
                  <Link to="/admin">
                    <Button className="top-back-btn" basic>
                      <Icon name="arrow left" />
                    Back
                    </Button>
                  </Link>
                  <hr />
                  {
                    this.state.userCanViewPage && (
                      <Grid columns={2}>
                        <Grid.Column>
                          <h2>
                            <b> User Details </b>
                          </h2>
                          <p>Add details of this user.</p>

                          <h5 className="user-label">Email</h5>
                          <Input placeholder="user@example.com" size="huge" value={userEmail} onChange={this.emailUpdated} />
                          <h5 className="user-label">Edit your clients</h5>
                          <Dropdown
                            placeholder="Clients"
                            fluid
                            multiple
                            selection
                            options={allClients}
                            value={selectedClients}
                            onChange={this.userClientsSelected}
                            loading={clientsLoading}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Container textAlign="justified" fluid className="user-agency">
                            <h3>Administrator Options</h3>
                            <h5 className="user-label">Account Status</h5>
                            <div className="status-switch">
                              <Checkbox toggle checked={userActive} onChange={this.checkboxChangeHandler} />
                              <span className="status-label">{userActive ? 'Active' : 'Inactive'}</span>
                            </div>
                            <Form.Field className="admin-form-field">
                              <h5 className="user-label">Works for Agency</h5>
                              <Dropdown
                                placeholder="Select Agency"
                                fluid
                                selection
                                options={agencyOptions}
                                value={selectedAgency}
                                onChange={this.agencySelected}
                                loading={agencyLoading}
                              />
                            </Form.Field>
                            <Form.Field className="admin-form-field">
                              <h5 className="user-label">Access Level</h5>
                              <Dropdown
                                placeholder="Access Level"
                                fluid
                                selection
                                options={roleOptions}
                                value={selectedRole}
                                onChange={this.selectRole}
                                loading={userRolesLoading}
                              />
                            </Form.Field>
                          </Container>
                        </Grid.Column>
                      </Grid>
                    )
                  }
                  {
                    this.state.userCanViewPage && (
                      <Button
                        primary
                        className="user-submit-btn"
                        onClick={this.addUser}
                        loading={updatingUserDetails}
                        disabled={updatingUserDetails}
                      >
                        Add User
                      </Button>
                    )
                  }
                  {
                    this.state.userCanViewPage && (
                      <Link to="/admin">
                        <Button className="user-submit-btn cancel-btn">
                        Cancel
                        </Button>
                      </Link>
                    )
                  }

                  {this.state.apiError && (<Message color="red">{errorMessage}</Message>)}
                  {this.state.apiSuccess && (<Message color="green">User created</Message>)}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserAdd.propTypes = {
  email: PropTypes.string.isRequired,
  loginUserInfo: PropTypes.object
};

export default connect(
  // eslint-disable-next-line no-unused-vars
  store => ({
    email: localStorage.getItem('iosEmail'),
    loginUserInfo: store.authInfo.loginUserInfo
  }),
  {}
)(UserAdd);

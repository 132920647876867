import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Header, Button, Icon, Loader
} from 'semantic-ui-react';
import {
  saveSalesHouseForecastSpendData
} from '../../actions/forecastSpendDataActions';
import {
  saveFinalisedMonthsData
} from '../../actions/finalisedMonthsActions';
import './TabDataEntry.css';
import DataEntryFiltersSidebar from '../DataEntryFiltersSidebar/DataEntryFiltersSidebar';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal';
import OMGModal from '../OMGModal/OMGModal';
import YearFilter from '../YearFilter/YearFilter';
import PivotTable from '../PivotTable/PivotTable';
import PivotTableAPI from '../../api/PivotTableAPI';
import processForecastSpendData from '../../lib/processForecastSpendData';
import { processYearlyActuals, processYearlyForecasts } from '../../lib/processYearlyTotal';
import { processMonthlyForecasts, processMonthlyActuals } from '../../lib/processMonthlyTotal';
import processFinalisedMonthsData from '../../lib/processFinalisedMonthsData';
import { SALESHOUSE_LEVEL, MONTHS } from '../../constants';
import FilterSummary from './FilterSummary';
import {
  saveYearlyForecastTotal,
  saveYearlyActualsTotal,
  saveMonthlyForecastTotal,
  saveMonthlyActualsTotal
} from '../../actions/totalSpendActions';

export class TabDataEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSavingChanges: false,
      showNotification: false,
      showPivotTable: false,
      showHeadingText: true,
      showUnsavedDataModal: false,
      showNoResultsModal: false,
      showFinalisedMonthSuccessModal: false,
      noResultsMessage: null,
      noResultsHeading: null,
      noResultsModalType: null,
      finalisedMonthNames: '',
      selectedFilterNames: {
        agencyTeam: '', clientGroup: '', brand: '', media: '', booking: ''
      },
      clienActualsLoading: false
    };

    this.getClientActuals = this.getClientActuals.bind(this);
    this.getClientActualsFilters = this.getClientActualsFilters.bind(this);
    this.saveForecasts = this.saveForecasts.bind(this);
    this.collectChangedForecasts = this.collectChangedForecasts.bind(this);
    this.collectChangedFinalisedMonths = this.collectChangedFinalisedMonths.bind(this);
    this.toggleUnsavedDataModalDisplay = this.toggleUnsavedDataModalDisplay.bind(this);
    this.toggleNoResultsModalDisplay = this.toggleNoResultsModalDisplay.bind(this);
    // eslint-disable-next-line max-len
    this.toggleFinalisedMonthSuccessModalDisplay = this.toggleFinalisedMonthSuccessModalDisplay.bind(this);
    this.onFiltersSubmission = this.onFiltersSubmission.bind(this);
  }

  /*
    This is a wrapper for getClientActuals,
    it checks if there is unsaved data before reloading data
  */
  onFiltersSubmission() {
    if (
      this.collectChangedForecasts(this.props.forecastSpendData).length > 0
      || this.collectChangedFinalisedMonths().length > 0
    ) {
      this.toggleUnsavedDataModalDisplay();
    } else {
      this.getClientActuals();
    }
  }

  /**
   * This function is used to fetch the names of the selected filters and display them
   * on the page with FilterSummary component.
   * Fields:
   *  1. brand
   *  2. media
   *  3. booking
   */
  getSelectedFilterNames() {
    try {
      const brand = this.props.menuFilters.brands.data.filter(
        b => b.id === this.props.menuFilters.brands.selectedBrand
      );
      const media = this.props.menuFilters.media.data.filter(
        m => m.id === this.props.menuFilters.media.selectedMedia
      );
      const booking = media[0].bookings.filter(
        b => b.id === this.props.menuFilters.media.selectedBooking
      );

      return {
        brand: brand[0].name,
        media: media[0].name,
        booking: booking[0].name
      };
    } catch (err) {
      return { brand: '', media: '', booking: '' };
    }
  }


  getClientActualsFilters(extraParams) {
    const filters = {
      year: this.props.menuFilters.years.selectedYear,
      client_id: this.props.menuFilters.brands.selectedBrand,
      ...extraParams
    };

    if (this.props.menuFilters.media.selectedMedia) {
      filters.media_id = this.props.menuFilters.media.selectedMedia;
    }

    if (this.props.menuFilters.media.selectedBooking) {
      filters.booking_id = this.props.menuFilters.media.selectedBooking;
    }

    return filters;
  }

  /**
   * Fetch Client Actuals for the selected filters.
   * The initial fetch with the top-level('Saleshouse') hierarchy is done here.
   * The subsequent child-level forecasts fetch for ('Folio', 'Supplier') levels is
   * is done in the PivotTable component.
   */
  async getClientActuals() {
    this.setState({ clienActualsLoading: true });
    try {
      // get data from API
      const {
        monthlyData: clientActuals,
        yearlyData,
        months: finalisedMonthsData
      } = await PivotTableAPI.getClientActuals(
        this.getClientActualsFilters()
      ).then(res => {
        this.setState({ clienActualsLoading: false });
        return res;
      });
      if (!clientActuals || clientActuals.length === 0) {
        const modalData = {
          noResultsHeading: 'Error',
          noResultsMessage: 'No suppliers are available for this client/booking category. Please select an alternative client and / or booking category and try again',
          noResultsModalType: 'error'
        };

        this.setState({ ...modalData }, () => this.toggleNoResultsModalDisplay());
      } else {
        // check if there is any forecast data
        const suppliersWithForecastData = clientActuals.filter(supplier => Object.prototype.hasOwnProperty.call(supplier, 'forecast'));

        if (!suppliersWithForecastData || suppliersWithForecastData.length === 0) {
          const modalData = {
            noResultsHeading: 'Warning',
            noResultsMessage: 'There are no forecast figures for this combination of client and media name / booking category. However you can still create forecasts if required.',
            noResultsModalType: 'warning'
          };

          this.setState({ ...modalData }, () => this.toggleNoResultsModalDisplay());
        }

        // Save yearly actuals total to the global store.
        this.props.saveYearlyActualsTotal(
          processYearlyActuals([], yearlyData.actuals, SALESHOUSE_LEVEL)
        );

        // Save yearly forecasts total to the global store.
        this.props.saveYearlyForecastTotal(
          processYearlyForecasts([], yearlyData.forecasts, SALESHOUSE_LEVEL)
        );
      }

      // save data from API to Redux store
      this.props.saveSalesHouseForecastSpendData(
        processForecastSpendData(clientActuals, { level: SALESHOUSE_LEVEL })
      );

      // Save monthly forecasts total to the global store.
      this.props.saveMonthlyForecastTotal(
        processMonthlyForecasts(this.props.forecastSpendData)
      );

      // Save monthly actuals total to the global store.
      this.props.saveMonthlyActualsTotal(
        processMonthlyActuals(this.props.forecastSpendData)
      );

      const selectedFilters = {
        year: this.props.menuFilters.years.selectedYear,
        client: this.props.menuFilters.brands.selectedBrand,
        media: this.props.menuFilters.media.selectedMedia,
        booking: this.props.menuFilters.media.selectedBooking
      };

      this.props.saveFinalisedMonthsData(
        processFinalisedMonthsData(finalisedMonthsData, selectedFilters)
      );

      this.setState({
        showPivotTable: true,
        showHeadingText: false,
        selectedFilterNames: this.getSelectedFilterNames()
      });

    // eslint-disable-next-line no-console
    } catch (e) { console.log('getClientActuals:', e); }
  }

  async saveForecasts() {
    this.setState({ isSavingChanges: true });
    const forecast = this.collectChangedForecasts(this.props.forecastSpendData);

    const changedMonthsFinalizeData = this.collectChangedFinalisedMonths();

    try {
      const response = await PivotTableAPI.saveForecasts({
        media_id: this.props.menuFilters.media.selectedMedia,
        booking_id: this.props.menuFilters.media.selectedBooking,
        advertiser_id: this.props.menuFilters.brands.selectedBrand,
        year: this.props.menuFilters.years.selectedYear,
        forecast_period: [...changedMonthsFinalizeData],
        forecast
      });

      this.notify(response ? 'positive' : 'negative');
    // eslint-disable-next-line no-console
    } catch (e) { console.log(e); }
    this.setState({
      isSavingChanges: false
    }, () => {
      this.getClientActuals();
      // we only need to show confirmation modal for finalised months
      const finalizedMonths = changedMonthsFinalizeData
        .filter(data => data.finalized === true);
      if (finalizedMonths.length > 0) {
        this.toggleFinalisedMonthSuccessModalDisplay();
      }
    });
  }

  collectChangedForecasts(data) {
    const childrenKeys = {
      Saleshouse: 'folios',
      Folio: 'suppliers'
    };

    const changedForecasts = Object.values(data).reduce((changedForecast, saleshouse) => {
      Object.keys(MONTHS).forEach(key => {
        // check if forecast has changed and if this element is the level at which data was entered
        if (saleshouse[MONTHS[key]]
          && saleshouse[MONTHS[key]].changed
          && saleshouse[MONTHS[key]].forecastLevel === saleshouse.level) {
          changedForecast.push({
            month: parseInt(key, 10) + 1,
            sales_house_id: saleshouse.SaleshouseId,
            folio_id: saleshouse.FolioId,
            supplier_id: saleshouse.SupplierId,
            forecast_level: saleshouse[MONTHS[key]].forecastLevel,
            metric: saleshouse[MONTHS[key]].forecast === '' ? 0 : saleshouse[MONTHS[key]].forecast
          });
        }
      });

      const children = saleshouse[childrenKeys[saleshouse.level]];
      if (children && Object.values(children).length) {
        changedForecast.push(...this.collectChangedForecasts(children));
      }

      return changedForecast;
    }, []);

    return changedForecasts;
  }

  collectChangedFinalisedMonths() {
    const changedFinalisedMonths = Object
      .values(this.props.finalisedMonthsData)
      .reduce((changedMonths, monthData) => {
        const {
          month,
          // eslint-disable-next-line camelcase
          media: media_id,
          // eslint-disable-next-line camelcase
          booking: booking_id,
          finalized
        } = monthData;

        if (Object.prototype.hasOwnProperty.call(monthData, 'changed')) {
          changedMonths.push({
            month,
            media_id,
            finalized,
            booking_id
          });
        }
        return changedMonths;
      }, []);

    const finalisedMonthNames = changedFinalisedMonths
      .map(monthData => MONTHS[monthData.month - 1]);

    this.setState({
      finalisedMonthNames: `${finalisedMonthNames.join(', ')} Month(s) Finalised`
    });

    return changedFinalisedMonths;
  }

  notify(state) {
    this.setState({ showNotification: state });
    setTimeout(() => this.setState({ showNotification: false }), 5000);
  }

  toggleUnsavedDataModalDisplay() {
    this.setState(state => (
      { showUnsavedDataModal: !state.showUnsavedDataModal }));
  }

  toggleNoResultsModalDisplay() {
    this.setState(state => ({
      showNoResultsModal: !state.showNoResultsModal
    }));
  }

  toggleFinalisedMonthSuccessModalDisplay() {
    this.setState(state => ({
      showFinalisedMonthSuccessModal: !state.showFinalisedMonthSuccessModal
    }));
  }

  render() {
    return (
      <div id="TabDataEntry">
        {this.state.showUnsavedDataModal && (
          <UnsavedChangesModal
            toggleDisplay={this.toggleUnsavedDataModalDisplay}
            isOpen={this.state.showUnsavedDataModal}
            getClientActuals={this.getClientActuals}
            saveForecasts={this.saveForecasts}
            selectedYear={this.props.menuFilters.years.selectedYear}
          />
        )}
        <OMGModal
          isOpen={this.state.showFinalisedMonthSuccessModal}
          message="The figures for the selected month(s) have been marked as final and can no longer be amended without reopening the month."
          heading={this.state.finalisedMonthNames}
          modalType="success"
          buttons={[
            { name: 'Continue', callback: this.toggleFinalisedMonthSuccessModalDisplay }
          ]}
          toggleDisplay={this.toggleFinalisedMonthSuccessModalDisplay}
        />

        {(this.state.showNoResultsModal && this.state.showFinalisedMonthSuccessModal !== true) && (
          <OMGModal
            isOpen={this.state.showNoResultsModal}
            message={this.state.noResultsMessage}
            heading={this.state.noResultsHeading}
            modalType={this.state.noResultsModalType}
            buttons={[
              { name: 'Continue', callback: this.toggleNoResultsModalDisplay }
            ]}
            toggleDisplay={this.toggleNoResultsModalDisplay}
          />
        )}
        <DataEntryFiltersSidebar
          onFiltersSubmission={this.onFiltersSubmission}
        />
        <div id="main-container">
          <div className="header-section">
            <Header as="h2">Investment forecasts</Header>
            {this.state.showHeadingText && (
              <p>
                Select a Client / Advertiser to view and confirm the current share deal spends.
                <Loader as="span" active={this.state.isSavingChanges} inline size="mini" inverted />
              </p>
            )}
          </div>
          <Loader className="client-actual-loader" active={this.state.clienActualsLoading} size="large" inline="centered" />

          {this.state.showPivotTable
            && (
              <>
                <FilterSummary summaryData={this.state.selectedFilterNames} />

                <div id="year-filter">
                  <YearFilter
                    getClientActuals={this.getClientActuals}
                    showLabel
                    fluid={false}
                    triggerTableRefresh
                  />
                </div>

                <div id="overflowing-container">
                  <PivotTable
                    getClientActualsFilters={this.getClientActualsFilters}
                  />

                  <div>
                    { this.state.showNotification
                      && (
                        <span className={`notification ${this.state.showNotification}`}>
                          <Icon name="checkmark" />
                            Saved
                        </span>
                      )}
                  </div>
                  <div id="bottom-buttons">
                    <div>
                      <Button
                        primary
                        onClick={this.saveForecasts}
                        disabled={!Object.keys(this.props.forecastSpendData).length}
                      >
                        <Loader active={this.state.isSavingChanges} inline size="mini" inverted />
                        {' '}
                          Save Changes
                      </Button>
                      <Button
                        basic
                        onClick={this.getClientActuals}
                        disabled={!Object.keys(this.props.forecastSpendData).length}
                      >
                        Cancel
                      </Button>
                    </div>
                    {/* <Button basic disabled>
                    <Icon name='balance scale'>Rebalance Calculator</Button>
                    */}
                    {/* <Button basic disabled>
                    <Icon name='file excel outline'/>Export as XLS</Button>
                    */}
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    );
  }
}

TabDataEntry.propTypes = {
  menuFilters: PropTypes.objectOf(PropTypes.object).isRequired,
  forecastSpendData: PropTypes.objectOf(PropTypes.object).isRequired,
  finalisedMonthsData: PropTypes.objectOf(PropTypes.object).isRequired,
  saveSalesHouseForecastSpendData: PropTypes.func.isRequired,
  saveYearlyActualsTotal: PropTypes.func.isRequired,
  saveYearlyForecastTotal: PropTypes.func.isRequired,
  saveMonthlyForecastTotal: PropTypes.func.isRequired,
  saveMonthlyActualsTotal: PropTypes.func.isRequired,
  saveFinalisedMonthsData: PropTypes.func.isRequired
};

export default connect(
  store => ({
    menuFilters: store.menuFilters,
    forecastSpendData: store.forecastSpendData,
    finalisedMonthsData: store.finalisedMonths
  }),
  {
    saveSalesHouseForecastSpendData,
    saveFinalisedMonthsData,
    saveYearlyActualsTotal,
    saveYearlyForecastTotal,
    saveMonthlyForecastTotal,
    saveMonthlyActualsTotal
  }
)(TabDataEntry);

import axios from 'axios';

const MenuFiltersAPI = {
  getBreadcrumb: async () => {
    const breadcrumb = await axios.get(process.env.REACT_APP_BREADCRUMB_URL, {
      headers: {
        Authorization: localStorage.getItem('iosToken')
      }
    });
    return breadcrumb.data;
  },

  // Fetch agency list with name and Ids.
  getAgency: async () => {
    const agencyList = await axios.get(
      process.env.REACT_APP_FILTERS_URL,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return agencyList.data;
  },

  getFilters: async agencyId => {
    const filters = await axios.get(
      `${process.env.REACT_APP_FILTERS_URL}${agencyId}/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );

    const brandsWithClients = filters.data.brands.data;

    // attach media letter to the media name.
    const mediaWithLetters = filters.data.media.data.map(mediaData => ({
      id: mediaData.id,
      name: `${mediaData.name} (${mediaData.letter})`,
      bookings: mediaData.bookings
    }));

    filters.data.media.data = [...mediaWithLetters];

    filters.data.brands.data = [...brandsWithClients];

    // allow users to enter forecasts for next year
    // atm filters api is not returning next year

    // next year will be returned by api when
    // we load all the data into our api database

    // so we add next year into the year filter here
    // (if it is not being returned by api)
    const nextYear = new Date().getFullYear() + 1;
    const hasNextYear = filters.data.years.data.includes(nextYear);

    if (!hasNextYear) {
      filters.data.years.data.push(nextYear);
    }
    // Sort years with newest appearting first.
    filters.data.years.data.sort((a, b) => b - a);

    // set selected year to current year (default)
    const currentYear = new Date().getFullYear();

    if (filters.data.years.data.includes(currentYear) === true) {
      filters.data.years.selectedYear = currentYear;
    }

    return filters.data;
  }
};

export default MenuFiltersAPI;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Icon,
  Segment
} from 'semantic-ui-react';
import { saveMenuFiltersData } from '../../actions/menuFiltersActions';
import './DataEntryFiltersSidebar.css';
import MenuFiltersAPI from '../../api/MenuFiltersAPI';

import ClientFilter from '../ClientFilter/ClientFilter';
import BookingCategoryFilter from '../BookingCategoryFilter/BookingCategoryFilter';


export class DataEntryFiltersSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarVisible: true
    };

    this.getFiltersOptions = this.getFiltersOptions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedAgencyId) {
      this.getFiltersOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedAgencyId !== this.props.selectedAgencyId) {
      this.getFiltersOptions(true);
    }
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.toggleSidebar();
    }
  }

  async getFiltersOptions(changedAgency = false) {
    // load options if they haven't already been loaded or if agency has changed
    const shouldLoadOptions = changedAgency
      || !this.props.menuFilters.brands.data.length
      || !this.props.menuFilters.media.data.length;

    if (shouldLoadOptions) {
      try {
        const filtersOptions = await MenuFiltersAPI.getFilters(this.props.selectedAgencyId);
        this.props.saveMenuFiltersData(filtersOptions);
      } catch (e) { console.log(e); }
    }
  }

  toggleSidebar() {
    this.setState(prevState => (
      { isSidebarVisible: !prevState.isSidebarVisible }
    ));
  }

  render() {
    return (
      <div id="DataEntryFiltersSidebar">
        <Segment
          inverted
          id="filter-sidebar"
          className={this.state.isSidebarVisible ? '' : 'hidden'}
        >
          <div
            id="toggle-sidebar"
            onClick={this.toggleSidebar}
            onKeyDown={this.onKeyDown}
            role="link"
            tabIndex={0}
            aria-label="Toggle sidebar"
          >
            <Icon inverted name={`chevron ${this.state.isSidebarVisible ? 'left' : 'right'}`} />
            { !this.state.isSidebarVisible && (
              <div className="filter-text">
                <span>Filters</span>
              </div>
            )}
          </div>

          <ClientFilter />

          <Divider />

          <BookingCategoryFilter />

          <Divider />

          { /* <span>Group or Agency-specific deals</span>

          <Divider /> */ }

          <Button primary onClick={this.props.onFiltersSubmission}>Go</Button>
        </Segment>

      </div>
    );
  }
}

DataEntryFiltersSidebar.propTypes = {
  onFiltersSubmission: PropTypes.func.isRequired,
  selectedAgencyId: PropTypes.number,
  menuFilters: PropTypes.objectOf(PropTypes.object).isRequired,
  saveMenuFiltersData: PropTypes.func.isRequired
};

export default connect(
  store => ({
    selectedAgencyId: store.breadcrumb.selectedAgency,
    menuFilters: store.menuFilters
  }),
  { saveMenuFiltersData }
)(DataEntryFiltersSidebar);

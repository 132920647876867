import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid, Header, Table, Loader, Message, Button, Icon
} from 'semantic-ui-react';
import ConfigTableAPI from '../../api/ConfigTablesAPI';

export class IosBuyerMappingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iosBuyers: [],
      fetching: true,
      apiError: false
    };
  }

  componentDidMount() {
    if (this.props.selectedAgency) {
      this.fetchIosBuyers(this.props.selectedAgency);
    }
  }

  componentDidUpdate(previous) {
    if (previous.selectedAgency !== this.props.selectedAgency) {
      this.fetchIosBuyers(this.props.selectedAgency);
    }
  }

  editAdvertiser(advertiser) {
    if (!advertiser.id) {
      return null;
    }
    const path = `agency/${advertiser.agency_id}/ios-advertisers/${advertiser.id}/`;
    this.props.history.push(path);
    return true;
  }

  async fetchIosBuyers() {
    this.setState({ apiError: false, fetching: true, iosBuyers: [] });
    try {
      const iosBuyers = await ConfigTableAPI.getIosBuyers(this.props.selectedAgency);
      this.setState({ iosBuyers, fetching: false });
    } catch (error) {
      this.setState({ fetching: false, apiError: true });
    }
  }

  render() {
    return (
      <div id="IosBuyers">
        <Grid>
          <Grid.Column>
            <Header as="h2"><b>List of Advertisers with associated client and client groups</b></Header>
            <p>All known advertisers and the client/client-groups they are represented by.</p>
            {
              !!this.state.iosBuyers.length && (
                <Link to="/ios-advertisers/new">
                  <Button primary className="top-back-btn">
                    Add IOS Advertiser
                  </Button>
                </Link>
              )
            }
            <Loader active={this.state.fetching} inline="centered" />
            {
              !!this.state.iosBuyers.length && (
                <Table celled padded striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell singleLine>Advertiser</Table.HeaderCell>
                      <Table.HeaderCell>Level</Table.HeaderCell>
                      <Table.HeaderCell>Client Group</Table.HeaderCell>
                      <Table.HeaderCell>Client</Table.HeaderCell>
                      <Table.HeaderCell>Agency</Table.HeaderCell>
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.iosBuyers.map((mn, index) => (
                      <tr key={index}>
                        <td>{mn.name}</td>
                        <td>{mn.level}</td>
                        <td>{mn.client_group_name}</td>
                        <td>{mn.client_name}</td>
                        <td>{mn.agency_name}</td>
                        <td>
                          <Button className="top-back-btn ui icon button" size="large" basic onClick={() => this.editAdvertiser(mn)} >
                            <Icon name="edit" />
                          </Button>

                        </td>
                      </tr>
                    ))}
                  </Table.Body>
                </Table>
              )
            }

            {this.state.apiError && (<Message color="red">Error fetching media names</Message>)}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

IosBuyerMappingPage.defaultProps = {
  selectedAgency: null
};
IosBuyerMappingPage.propTypes = {
  selectedAgency: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  store => ({
    selectedAgency: store.breadcrumb.selectedAgency
  }),
  {}
)(withRouter(IosBuyerMappingPage));

import React from 'react';
import { connect } from 'react-redux';
import { Tab, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import OMGBreadcrumb from '../OMGBreadcrumb/OMGBreadcrumb';
import SubTab from '../SubTab/SubTab';
import './HomePage.css';
import getSubTabPanes from '../../lib/getSubTabPanes';

export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panes: [
        {
          menuItem: { name: 'Deal Management', key: 'deal-management', onClick: () => this.redirect('/') },
          tab: 'deal-management',
          render: () => (
            <SubTab panes={[...getSubTabPanes(localStorage.iosRole)]} />
          )
        }
        // {
        //   menuItem: { name: 'Forecasting', key: 'forecasting', onClick: () => this.redirect('/forecasting'), disabled: true },
        //   tab: 'forecasting',
        //   render: () => <Tab.Pane attached={false}>Forecasting</Tab.Pane>
        // },
        // {
        //   menuItem: { name: 'Value Tracking', key: 'value-tracking', onClick: () => this.redirect('/value-tracking'), disabled: true },
        //   tab: 'value-tracking',
        //   render: () => <Tab.Pane attached={false}>Value Tracking</Tab.Pane>
        // },
        // {
        //   menuItem: { name: 'Planning', key: 'planning', onClick: () => this.redirect('/planning'), disabled: true },
        //   tab: 'planning',
        //   render: () => <Tab.Pane attached={false}>Planning</Tab.Pane>
        // }
      ]
    };

    this.getActiveTab = this.getActiveTab.bind(this);
  }

  getActiveTab() {
    const index = this.state.panes.findIndex(pane => pane.tab === this.props.tab);
    return index > 0 ? index : 0;
  }

  render() {
    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />

        <div id="HomePage">
          <div className="title-breadcrumb">
            <Header as="h1">Investment Operating System</Header>
            <OMGBreadcrumb />
          </div>

          <div id="container">
            <Tab
              menu={{ secondary: true, pointing: true }}
              activeIndex={this.getActiveTab()}
              panes={this.state.panes}
            />
          </div>
        </div>

      </div>
    );
  }
}

HomePage.propTypes = {
  tab: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export default connect(
  () => ({
    email: localStorage.getItem('iosEmail') // store.user.email
  }),
  null
)(HomePage);

import _ from 'lodash';
import Big from 'big.js';
import { MONTHS } from '../constants';


/**
 * Process ACTUAL for each saleshouse and save total for each month
 * @param {*} data
 */
export function processMonthlyActuals(data) {
  try {
    if (Object.entries(data).length === 0) {
      return [];
    }

    const monthsData = MONTHS.map(month => {
      const formattedMonth = _.capitalize(month);
      const saleshousesData = Object.values(data);

      const actualsByMonth = saleshousesData.reduce((grouped, saleshouseData) => {
        const actual = saleshouseData[formattedMonth].actual === null
          ? new Big('0.00')
          : new Big(saleshouseData[formattedMonth].actual);

        if (!grouped[formattedMonth]) {
          grouped[formattedMonth] = { actual };
        } else {
          const currentActualTotal = new Big(grouped[formattedMonth].actual);

          const newActualTotal = currentActualTotal.plus(actual);

          grouped[formattedMonth] = {
            month: formattedMonth,
            actual: newActualTotal.toFixed(2)
          };
        }

        return grouped;
      }, {});

      return actualsByMonth[formattedMonth];
    });
    console.log(monthsData);
    return monthsData;
  } catch (error) {
    return [];
  }
}

/**
 * Process FORECAST for each saleshouse and save total for each month
 * @param {*} data
 */
export function processMonthlyForecasts(data) {
  try {
    if (Object.entries(data).length === 0) {
      return [];
    }

    const monthsData = MONTHS.map(month => {
      const formattedMonth = _.capitalize(month);
      const saleshousesData = Object.values(data);

      const forecastsByMonth = saleshousesData.reduce((grouped, saleshouseData) => {
        const forecast = saleshouseData[formattedMonth].forecast === null
          ? new Big('0.00')
          : new Big(saleshouseData[formattedMonth].forecast);

        if (!grouped[formattedMonth]) {
          grouped[formattedMonth] = { forecast };
        } else {
          const currentForecastTotal = new Big(grouped[formattedMonth].forecast);

          const newForecastTotal = currentForecastTotal.plus(forecast);

          grouped[formattedMonth] = {
            month: formattedMonth,
            forecast: newForecastTotal.toFixed(2)
          };
        }

        return grouped;
      }, {});

      return forecastsByMonth[formattedMonth];
    });
    return monthsData;
  } catch (error) {
    return [];
  }
}

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import {
  changeMediaName,
  changeBookingCategory
} from '../../actions/menuFiltersActions';
import makeDropdownOptions from '../../lib/makeDropdownOptions';


export class BookingCategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: []
    };

    this.TELEVISION = 'TELEVISION';
    this.TV = 'TV';
    this.changeMediaName = this.changeMediaName.bind(this);
    this.changeBookingCategory = this.changeBookingCategory.bind(this);
  }

  componentDidMount() {
    if (this.props.media.data.length) {
      // eslint-disable-next-line
      const { televisionMediaId, tvBookingId } = this.televisionTvMediaBookingId();
      this.changeMediaName(televisionMediaId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.media.data !== this.props.media.data) {
      // eslint-disable-next-line
      const {televisionMediaId, tvBookingId} = this.televisionTvMediaBookingId();
      this.changeMediaName(televisionMediaId);
    }

    // the changeMediaName function takes care of the selectedBooking we get
    // from the redux store.
    if (this.props.media.selectedBooking !== prevProps.media.selectedBooking) {
      // this.changeMediaName(this.props.media.selectedMedia);
    }
  }

  /**
   * Find the media id for TELEVISION and booking id for TV.
   */
  televisionTvMediaBookingId() {
    const tvMedia = this.props.media.data.find(m => m.name.indexOf(this.TELEVISION) !== -1);
    const televisionMediaId = tvMedia && 'id' in tvMedia ? tvMedia.id : this.props.media.data[0].id;

    const tvBooking = this.props.media.data.find(
      m => m.id === televisionMediaId
    ).bookings.find(b => b.name === this.TV);

    const tvBookingId = tvBooking && 'id' in tvBooking ? tvBooking.id : this.props.media.data.find(
      m => m.id === televisionMediaId
    ).bookings[0].id;

    return { televisionMediaId, tvBookingId };
  }

  /**
   * When a user selects a Media from a filter display the associated bookings.
   * When a user selects Media TELEVISION, display Booking TV as default.
   * @param {*} id integer id of user selected Media.
   */
  changeMediaName(id) {
    // Get the TELEVISION Media id and TV Booking id.
    const { televisionMediaId, tvBookingId } = this.televisionTvMediaBookingId();

    // Fetch the bookings associated with the selected Media id.
    const { bookings } = this.props.media.data.find(m => m.id === id);

    // If the user is selecting TELEVISION Media, display TV booking by default.
    const wantedTvBookingId = id === televisionMediaId
      ? tvBookingId
      : this.props.media.selectedBooking;

    let selectedBooking = bookings.find(b => (b.id === wantedTvBookingId));

    selectedBooking = selectedBooking
      ? selectedBooking.id
      : bookings[0].id;

    this.props.changeMediaName(id);
    this.setState({ bookings }, () => this.changeBookingCategory(selectedBooking));
  }

  changeBookingCategory(id) {
    this.props.changeBookingCategory(id);
  }


  render() {
    return (
      <>
        <span id="media-label">Media Name</span>
        <Dropdown
          fluid
          selection
          loading={!this.props.media.data.length}
          placeholder="Select a Media"
          options={makeDropdownOptions(this.props.media.data, 'name', 'id')}
          value={this.props.media.selectedMedia}
          onChange={(e, { value }) => this.changeMediaName(value)}
          aria-labelledby="media-label"
        />

        <span id="booking-category-label">Booking Category</span>
        <Dropdown
          fluid
          selection
          loading={!this.state.bookings.length}
          placeholder="Select a Booking Category"
          options={makeDropdownOptions(this.state.bookings, 'name', 'id')}
          value={this.props.media.selectedBooking}
          onChange={(e, { value }) => this.changeBookingCategory(value)}
          aria-labelledby="booking-category-label"
        />
      </>
    );
  }
}

BookingCategoryFilter.propTypes = {
  media: PropTypes.exact({
    data: PropTypes.array,
    selectedMedia: PropTypes.number,
    selectedBooking: PropTypes.number
  }).isRequired,
  changeMediaName: PropTypes.func.isRequired,
  changeBookingCategory: PropTypes.func.isRequired
};

export default connect(
  store => ({
    media: store.menuFilters.media
  }),
  {
    changeMediaName,
    changeBookingCategory
  }
)(BookingCategoryFilter);

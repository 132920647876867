import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import thunk from 'redux-thunk';
// eslint-disable-next-line
import 'semantic-ui-less/semantic.less';
import AuthAPI from './api/AuthAPI';
import { logout, saveLoginUserInfo } from './actions/authActions';

import './index.css';
import App from './App';
import rootReducer from './rootReducer';
// import * as serviceWorker from './serviceWorker';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(rootReducer, enhancer);

const isIE = /* @cc_on!@ */ false || !!document.documentMode;

AuthAPI.isTokenAuthentic().then(res => {
  const isAuthentic = res.status === 200;
  const { data } = res
  if (!isAuthentic) {
    store.dispatch(logout());
  } else {
    store.dispatch(saveLoginUserInfo(data));
 }

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <Route component={isIE ? <h1>Error IE</h1> : App} />
      </Provider>
    </BrowserRouter>,
    document.getElementById('root')
  );
});

// serviceWorker.unregister();

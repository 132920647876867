import React from 'react';
import { Container } from 'semantic-ui-react';
import TabDataEntry from '../components/TabDataEntry/TabDataEntry';
import TabSummary from '../components/TabSummary/TabSummary';

/**
 * Helper function to return tab pane(s) depending on users role
 *
 * @param {string} role
 *
 * @returns {Array} paneObjects
 */
export default function getSubTabPanes(role) {
  const panes = [
    {
      menuItem: 'Data Entry',
      pane: {
        key: 'data-entry-tab',
        content: <TabDataEntry />,
        as: Container,
        className: 'fluid'
      }
    }
  ];

  // Temporarily hide summary tab from production
  if (document.location.hostname.startsWith('ios.oneomg.com') === false) {
    if (role === 'Region Admin' || role === 'Agency Head') {
      panes.push(
        {
          menuItem: 'Summary',
          pane: {
            key: 'summary-tab',
            content: <TabSummary />,
            as: Container,
            className: 'fluid'
          }
        }
      );
    }
  }

  return panes;
}

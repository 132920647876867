/**
 * Helper function to create array of options object for Semantic UI Dropdown.
 * If neither textKey nor valueKey are provided and if data is an array of options, it returns the
 * same value in text and value
 * @param {Array} data
 * @param {String} textKey key to be used for the name
 * @param {String} valueKey key to be used for the value
 * @returns {Array} format [ { text: 'name', value: 'value' } ]
 */
export default function makeDropdownOptions(data, textKey=undefined, valueKey=undefined) {
  return data.map(option => ({
    text: textKey ? option[textKey] : option,
    value: valueKey ? option[valueKey] : option
  }));
}

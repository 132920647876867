import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';
import DevLoginForm from '../LoginForm/DevLoginForm';
import { devLogin } from '../../actions/authActions';
import './LoginPage.css';

class DevLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(data) {
    await this.props.devLogin(data);
    this.props.history.push(
      this.props.location.state && this.props.location.state.from
        ? this.props.location.state.from.pathname
        : '/'
    );
  }

  render() {
    return (
      <div id="LoginPage">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <Header as="h2" color="blue" textAlign="center">
              Log-in to IOS
            </Header>
            <DevLoginForm submit={this.submit} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

DevLoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  devLogin: PropTypes.func.isRequired

};

export default connect(null, { devLogin })(DevLoginPage);

import React from 'react'
import {
  Button,
  Header,
  Icon,
  Modal
} from 'semantic-ui-react'
import './UnsavedChangesModal.css'

export class UnsavedChangesModal extends React.Component {
  render(){
    return(     
      <Modal 
        size="small" 
        className="warningModal"
        closeIcon
        open={this.props.isOpen}
        onClose={this.props.toggleDisplay}
      >
      <Header><Icon name="warning sign" size="small" /> Save changes? </Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            You've made some updates to the Live Forecasts for this client. Do you wish to save those changes before continuing?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button 
          basic 
          onClick={() => {
            this.props.toggleDisplay()
            this.props.getClientActuals()
          }}
          className="float-left"
        >Continue without saving</Button>
        <Button 
          basic
          onClick={() => {
            this.props.toggleDisplay()
          }}
          >Stay on this page</Button>
        <Button 
          primary
          onClick={() => {
            this.props.toggleDisplay()
            this.props.saveForecasts()
          }}
        >Save and continue</Button>
      </Modal.Actions>
    </Modal>
    )
  }
}

export default UnsavedChangesModal
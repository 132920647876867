import React from 'react';
import {
  Header
} from 'semantic-ui-react';
import SummaryTable from '../SummaryTable/SummaryTable';
import SummaryFilters from '../SummaryFilters/SummaryFilters';

function TabSummary() {
  return (
    <>
      <Header as="h2">
        Investment forecasts
      </Header>
      <SummaryFilters />
      <SummaryTable />
    </>
  );
}

export default TabSummary;

import types from '../types/adminPageTypes';

const initialState = {
  activeIndex: null
};

export default function adminPageInfo(state = initialState, action) {
  switch (action.type) {
    case types.SET_ACTIVE_INDEX:
      return { ...state, activeIndex: action.data };

    default:
      return state;
  }
}

import {
  SAVE_SALES_HOUSE_FORECAST_SPEND_DATA,
  SAVE_FOLIO_FORECAST_SPEND_DATA,
  SAVE_SUPPLIER_HOUSE_FORECAST_SPEND_DATA,
  TOGGLE_COLLAPSE_ROW,
  CHANGE_FORECAST_VALUE,
  CHANGE_FORECAST_LEVEL,
  CHANGE_TEMP_FORECAST_LEVEL
} from '../types/forecastSpendDataTypes';


export const saveSalesHouseForecastSpendData = data => dispatch => {
  dispatch({
    type: SAVE_SALES_HOUSE_FORECAST_SPEND_DATA,
    data
  });
};

export const saveFolioForecastSpendData = data => dispatch => {
  dispatch({
    type: SAVE_FOLIO_FORECAST_SPEND_DATA,
    data
  });
};

export const saveSupplierForecastSpendData = data => dispatch => {
  dispatch({
    type: SAVE_SUPPLIER_HOUSE_FORECAST_SPEND_DATA,
    data
  });
};

export const toggleCollapseRow = data => dispatch => {
  dispatch({
    type: TOGGLE_COLLAPSE_ROW,
    data
  });
};

export const changeForecastValue = data => dispatch => {
  dispatch({
    type: CHANGE_FORECAST_VALUE,
    data
  });
};

export const changeForecastLevel = data => dispatch => {
  dispatch({
    type: CHANGE_FORECAST_LEVEL,
    data
  });
};

export const changeTempForecastValue = data => dispatch => {
  dispatch({
    type: CHANGE_TEMP_FORECAST_LEVEL,
    data
  });
};

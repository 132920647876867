import axios from 'axios';

const UsersAPI = {
  getAgencyUsers: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/${agencyId}/users/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getUserDetails: async (agencyId, userId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/${agencyId}/users/${userId}`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  updateUser: async userDetails => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}users/${userDetails.user_id}`,
      userDetails,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken'),
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  },
  createUser: async userDetails => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}users/`,
      userDetails,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken'),
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  },
  getUserAgencies: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getAgencyBuyers: async agencyId => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}agency/${agencyId}/buyers/`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  },
  getUserRoles: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}user-roles`,
      {
        headers: {
          Authorization: localStorage.getItem('iosToken')
        }
      }
    );
    return response.data;
  }
};

export default UsersAPI;

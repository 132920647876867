import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './ReopenFinalizedMonth.css';

function ReopenFinalizedMonth(props) {
  return (
    <Icon
      name="pencil"
      className="edit-finalised-month"
      onClick={() => props.onClick(props.month, true)}
    />
  );
}

ReopenFinalizedMonth.propTypes = {
  onClick: PropTypes.func.isRequired,
  month: PropTypes.string.isRequired
};

export default ReopenFinalizedMonth;

/**
 * Adds commas to separate thousands in number.
 * @param {number} number number
 */
export default function numberWithCommas(number) {
  if (!number) { return ''; }

  let cleanedNumber = number;
  // handle 'string' numbers with commas
  if (typeof cleanedNumber === 'string') {
    cleanedNumber = cleanedNumber.replace(/,/g, '');
  }

  return Number(cleanedNumber).toLocaleString('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

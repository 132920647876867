import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form, Container, Header, Button, Message, Loader, Dropdown
} from 'semantic-ui-react';
import OMGNavigation from '../OMGNavigation/OMGNavigation';
import ForecastAPI from '../../api/ForecastAPI';
import MenuFiltersAPI from '../../api/MenuFiltersAPI';

/**
 * Accepts CSV file and uploads it to the API.
 */
export class ForecastUploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvName: null,
      csvFile: null,
      errorMessage: null,
      successMessage: null,
      postingCSV: false,
      agencies: [],
      selectedAgency: false,
      agencyLoading: false
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.uploadForecast = this.uploadForecast.bind(this);
    this.agencyOptionsGet = this.agencyOptionsGet.bind(this);
  }

  /**
   * Fetch the list of agency/id to be displayed in the form select when the component mounts.
   */
  componentDidMount() {
    this.agencyOptionsGet();
  }

  /**
   * Fetch the list of agency/id to be displayed in the form select when the component updates.
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.agencies.length !== this.state.agencies.length) {
      if (this.state.agencies.length < 1) {
        this.agencyOptionsGet();
      }
    }
  }

  /**
   * Fetch the agency list for the dropdown option.
   * Format the data, add the agency id as the key field.
   */
  async agencyOptionsGet() {
    try {
      if (!this.state.agencies.length) {
        this.setState({ agencyLoading: true });
        let agencyData = await MenuFiltersAPI.getAgency();
        agencyData = agencyData.map(d => {
          d.key = `${d.name}-${d.id}`;
          d.text = d.name;
          d.value = d.id;
          return d;
        });
        this.setState({ agencies: agencyData, errorMessage: null, agencyLoading: false });
      }
    } catch (err) {
      this.setState({ agencies: [], agencyLoading: false, errorMessage: `Error fetching Agencies:${err}` });
    }
  }

  /**
   * Make a call to the Upload CSV API when the button is clicked.
   * Attach the csv file and the 'hard-coded' agency id as the form data.
   */
  async uploadForecast() {
    this.setState({
      errorMessage: null,
      successMessage: null,
      postingCSV: true

    });
    try {
      // Do not make a API call if the Agency or the CSV is empty.
      if (!this.state.selectedAgency) {
        this.setState({ errorMessage: 'Agency not selected.', postingCSV: false });
        return null;
      }

      if (!this.state.csvName) {
        this.setState({ errorMessage: 'No CSV file selected.', postingCSV: false });
        return null;
      }

      const formData = new FormData();
      formData.append('forecast_csv', this.state.csvFile);
      formData.append('agency_id', this.state.selectedAgency);
      const uploadResult = await ForecastAPI.uploadForecast(formData);
      const processedCSV = this.state.csvName;

      this.setState({
        postingCSV: false,
        successMessage: `${processedCSV} uploaded. ${JSON.stringify(
          uploadResult.data.message
        )}`
      });
    } catch (err) {
      let errorMessage = JSON.stringify(err);
      if (err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      this.setState({
        errorMessage,
        successMessage: null,
        postingCSV: false
      });
    }
    return null;
  }

  /**
   * Update state variables when the user selects a file.
   * Clear previous error and success messages.
   * @param {*} event JS event object
   */
  handleFileChange(event) {
    try {
      this.setState({ successMessage: null });
      this.setState({
        csvFile: event.target.files[0],
        csvName: event.target.files[0].name
      });
      this.setState({ errorMessage: null });
    } catch (err) {
      this.setState({ errorMessage: JSON.stringify(err) });
    }
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div>
        <OMGNavigation userEmail={this.props.email} userRole={localStorage.iosRole} />
        <Container>
          <br />
          <Header as="h2">Forecast Upload</Header>
          <br />
          <Form.Group widths="equal">
            <span>Select Agency</span>
            <br />
            <Dropdown
              placeholder="Select Agency"
              selection
              options={this.state.agencies}
              aria-label="Select Agency"
              onChange={(e, { value }) => this.setState({ selectedAgency: value })}
              loading={this.state.agencyLoading}
            />
            <br />
          </Form.Group>
          <br />
          <Form.Group widths="equal">
            <input
              type="file"
              id="forecast-csv"
              accept=".csv"
              onChange={e => this.handleFileChange(e)}
              aria-label="Select CSV"
            />
          </Form.Group>
          <br />
          <br />
          <Button
            primary
            onClick={e => this.uploadForecast(e)}
            className="upload-button"
            disabled={this.state.postingCSV}
          >
            Upload Forecast &nbsp; &nbsp;
            { this.state.postingCSV && (<Loader active inline inverted size="tiny" />) }
          </Button>
          {this.state.errorMessage && (
            <Message negative>
              <Message.Header>{this.state.errorMessage}</Message.Header>
            </Message>
          )}
          {this.state.successMessage && (
            <Message positive>
              <Message.Header>{this.state.successMessage}</Message.Header>
            </Message>
          )}
        </Container>
      </div>
    );
  }
}
ForecastUploadPage.propTypes = {
  email: PropTypes.string.isRequired
};

export default connect(
  // eslint-disable-next-line no-unused-vars
  store => ({
    email: localStorage.getItem('iosEmail')
  }),
  null
)(ForecastUploadPage);

import React from 'react';
import { connect } from 'react-redux';
import {
  Table, Icon, Loader, Input
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { changeTempForecastValue } from '../../actions/forecastSpendDataActions';
import numberWithCommas from '../../lib/numberWithCommas';
import {
  MONTHS, SUPPLIER_LEVEL, ACTUALS, FORECASTS, SALESHOUSE_LEVEL, FOLIO_LEVEL
} from '../../constants';
import './PivotTableRow.css';
import OMGModal from '../OMGModal/OMGModal';

export class PivotTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      focussedLiveForecastValue: null,
      pasteEvent: false,
      originalPasted: null,
      pastedValue: null,
      showErrorModal: false,
      errorMessage: null,
      errorHeading: null
    };

    this.expandRow = this.expandRow.bind(this);
    this.getForecastValue = this.getForecastValue.bind(this);
    this.updateForecastData = this.updateForecastData.bind(this);
    this.handleLiveForecastChange = this.handleLiveForecastChange.bind(this);
    this.handleLiveForecastFocus = this.handleLiveForecastFocus.bind(this);
    this.handleLiveForecastBlur = this.handleLiveForecastBlur.bind(this);
    this.isFinalised = this.isFinalised.bind(this);
    this.toggleErrorModalDisplay = this.toggleErrorModalDisplay.bind(this);

    this.inputRef = [];
    // this.compileLiveForecasts = this.compileLiveForecasts.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line
    return this.props.supplier !== nextProps.supplierfalse;
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.inputRef[this.props.inputRefToFocus]) {
        this.inputRef[this.props.inputRefToFocus].focus();
      }
    }
  }

  getLiveForecastInputColour(month, level) {
    if (!this.props.supplier[month]) { return ''; }

    const { forecastLevel } = this.props.supplier[month];

    switch (true) {
      case (level === 'Saleshouse' && (forecastLevel === 'Folio' || forecastLevel === 'Supplier')):
        return 'sum';
      case (level === 'Folio' && forecastLevel === 'Supplier'):
        return 'sum';
      default:
        return '';
    }
  }

  /**
     * Color Codes for Actuals Vs Forecast Figures:
     *    1. No difference = Postive Green
     *    2. Difference between +4 and -4 = Amber Warning
     *    3. Difference >= +4 = Positive Danger Red
     *    4. Differene <=-4 = Negative Danger Red
     *
     * @param {*} value
     * @returns {String} The class of the cell which shows the color.
     */
  getVsForecastCellColour(value) {
    if (value === '') {
      return '';
    }
    const difference = Number(value);

    switch (true) {
      case difference === 0:
        return 'positive';
      case difference > -4 && difference < 0:
      case difference > 0 && difference < 4:
        return 'warning';
      case difference >= 4:
        return 'positive-danger';
      case difference <= -4:
        return 'negative-danger';
      default:
        return '';
    }
  }


  getForecastValue(month) {
    if (!this.props.supplier[month]) { return ''; }

    // using hasOwnProperty() because
    // 'this.props.supplier[month].changed'
    // will return false for empty values
    const value = Object.prototype.hasOwnProperty.call(this.props.supplier[month], 'changed') && this.props.supplier[month].changed !== true
      ? this.props.supplier[month].changed
      : this.props.supplier[month].forecast;

    return value === '' ? '' : numberWithCommas(value);
  }

  updateForecastData(value, month) {
    this.initiateLiveForecastChange(
      {
        saleshouseId: this.props.supplier.SaleshouseId,
        folioId: this.props.supplier.FolioId,
        supplierId: this.props.supplier.SupplierId,
        level: this.props.supplier.level,
        month,
        tempForecast: value
      }
    );

    this.props.handleLiveForecastChange(
      {
        saleshouseId: this.props.supplier.SaleshouseId,
        folioId: this.props.supplier.FolioId,
        supplierId: this.props.supplier.SupplierId,
        month
      },
      value
    );
  }

  initiateLiveForecastChange(data) {
    this.props.changeTempForecastValue(data);
  }

  /**
   * User wants to view suppliers at child levels(folio or supplier).
   */
  async expandRow() {
    this.setState({ isLoading: true });
    await this.props.getDeeperData(
      this.props.supplier.SaleshouseId,
      this.props.supplier.FolioId
    );
    this.setState({ isLoading: false });
  }

  /**
   * When a user enters a forecast value in a cell:
   *  1. re-calculate the total at the top levels.
   *  2. re-format the number entered with commas.
   * @param {*} month
   * @param {*} e
   */
  handleLiveForecastChange(month, e) {
    const displayValue = this.state.focussedLiveForecastValue;
    const oldLen = displayValue.length;

    const element = e.target;
    let rawString = element.value;

    // Check if the cell value was pasted.
    // Get the pasted value from the state and do a validation check.
    // If the pasted value is not a number then show error pop-up and do not display it on the cell.
    if (this.state.pasteEvent) {
      rawString = this.state.pastedValue;
      if (!rawString || Number.isNaN(numberWithCommas(rawString))) {
        const enteredForecast = this.state.originalPasted;
        this.setState({
          showErrorModal: true,
          errorHeading: 'Invalid forecast.',
          errorMessage: `The input forecast value:<${enteredForecast}> is invalid.`,
          pasteEvent: false,
          pastedValue: null,
          originalPasted: null
        });
        return;
      }
    }

    let caret = element.selectionStart;

    // block removing decimal point
    if (caret === element.value.length - 2 && element.value.length < oldLen) {
      rawString = `${rawString.slice(0, caret)}.${rawString.slice(caret)}`;
    }

    // block removing commas
    if (displayValue.charAt(caret) === ',' && rawString.charAt(caret) !== ',') {
      rawString = `${rawString.slice(0, caret)},${rawString.slice(caret)}`;
    }

    const firstCommaAt = rawString.search(/,/g);
    const newLen = rawString.length;

    // caret position correction
    if (newLen > oldLen) {
      if (
        (firstCommaAt === 3 && caret > firstCommaAt)
        || firstCommaAt === 4
        || newLen === 7
      ) {
        caret += 1;
      }
    }
    if (newLen < oldLen) {
      if (firstCommaAt === 1 && caret > firstCommaAt) {
        caret -= 1;
      }
    }
    const formattedNumber = numberWithCommas(rawString);

    // store parseable value and set pastEvent as false, pastedValue as null
    this.setState({
      focussedLiveForecastValue: formattedNumber,
      pasteEvent: false,
      pastedValue: null,
      originalPasted: null
    });

    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    this.updateForecastData(formattedNumber, month);
  }

  /**
   * UI/UX function to adapt cursor position when the user is in a cell.
   * @param {*} ref
   * @param {*} e
   */
  handleLiveForecastFocus(ref, e) {
    this.setState({ focussedLiveForecastValue: e.target.value });
    this.props.setInputRefToFocus(ref);
  }

  /**
   * UI/UX function to adapt cursor position when the user leaves a cell.
   */
  handleLiveForecastBlur() {
    this.setState({ focussedLiveForecastValue: null });
  }

  /**
   * Get the yearly total (actuals/forecasts) based on the totalType value.
   * The supplier level denotes which level it is fetching the values at.
   * @param {*} totalType string
   * @param {*} supplierLevel string
   * @param {*} saleshouseId integer
   * @param {*} folioId integer
   * @param {*} supplierId integer
   */
  fetchYearlyTotal(totalType, supplierLevel, saleshouseId, folioId, supplierId) {
    let result = '';

    try {
      const yData = this.props.yearlySpend[totalType].filter(d => {
        if (supplierLevel === SALESHOUSE_LEVEL) {
          return d.sales_house_id === saleshouseId;
        }
        if (supplierLevel === FOLIO_LEVEL) {
          return d.folio_id === folioId;
        }

        if (supplierLevel === SUPPLIER_LEVEL) {
          return d.supplier_id === supplierId;
        }
        return false;
      });

      if (yData) {
        result = yData[0].total;
      }
    } catch (err) {
      result = '';
    }
    return numberWithCommas(result);
  }

  /**
   * Return the boolean value of if a month has been marked as finalized.
   * @param {*} month
   */
  isFinalised(month) {
    if (!this.props.supplier[month]) { return false; }

    const { finalised } = this.props.supplier[month];
    return finalised;
  }

  /**
   * When copy pasting in a forecast cell, fetch the pasted value and set it in the state.
   * The pasted value (from Excel) might contain £ sign, commas, so we strip all special characters
   * except the dot symbol and save to the state.
   */
  handlePaste(e) {
    try {
      let pastedText = e.clipboardData.getData('Text');
      pastedText = pastedText.replace(/[^0-9.]/g, '');
      this.setState({
        pasteEvent: true,
        pastedValue: pastedText,
        originalPasted: e.clipboardData.getData('Text')
      });
    } catch (err) {
      this.setState({
        showErrorModal: true,
        errorHeading: 'Invalid forecast.',
        errorMessage: `The following error occured while pasting the forecast. ${err}`,
        pasteEvent: false,
        pastedValue: null,
        originalPasted: null
      });
    }
  }

  /**
   * Find the difference of actuals vs forecasts in percentage.
   *
   * Difference Percentage:
   *    ((Month Actual - Month Forecast) / Month Forecast) * 100
   *
   * If actual is populated but forecast is null then return 100%
   *
   * @param {Object} monthData with keys forecast and actual
   * @param {*} digits
   */
  calculateVsForecast(monthData, digits = null) {
    if (!monthData) {
      return '';
    }

    // eslint-disable-next-line no-empty
    if (monthData.actual !== null || monthData.forecast !== null) {
    } else {
      return '';
    }

    if (monthData.actual === 0 && monthData.forecast === 0) {
      return '';
    }

    if (monthData.actual !== null && !monthData.forecast) {
      return '100.0%';
    }

    const monthActual = !monthData.actual || Number.isNaN(monthData.forecast) ? 0 : monthData.actual;
    const monthForecast = !monthData.forecast || Number.isNaN(monthData.forecast) ? 0 : monthData.forecast;

    if (monthActual === 0 && monthForecast === 0) {
      return '';
    }

    let percentageDifference = Number.parseFloat(
      ((monthActual - monthForecast) / monthForecast) * 100
    );

    if (digits) {
      percentageDifference = percentageDifference.toFixed(digits);
    }

    return `${percentageDifference}%`;
  }


  /**
   * Function to hide/display error modal.
   */
  toggleErrorModalDisplay() {
    this.setState(state => ({
      showErrorModal: !state.showErrorModal
    }));
  }

  render() {
    const {
      level,
      name: supplierName,
      SaleshouseId: saleshouseId,
      FolioId: folioId = null,
      SupplierId: supplierId = null
    } = this.props.supplier;

    // this.compileLiveForecasts()
    const ref = `${saleshouseId}-${folioId}-${supplierId}`;
    return (
      <Table.Row>
        <Table.Cell className={`supplier-cell padding-${level}`}>
          <span
            onClick={this.expandRow}
            onKeyDown={this.expandRow}
            role="link"
            tabIndex={-1}
          >
            { this.props.supplier.level !== SUPPLIER_LEVEL && !this.state.isLoading
              && <Icon name={`${this.props.showChildren ? 'minus' : 'plus'} square outline`} /> }

            { this.state.isLoading
              && <Loader active inline size="mini" /> }

            {this.props.supplier.name}
          </span>

        </Table.Cell>

        { MONTHS.map(month => [ // live forecast, actuals and vs forecasts cells per month

          <Table.Cell key={`liveForecast${ref}`} className="live-forecast-cell">
            {this.props.finalisedMonthsData[month].finalized !== true && (
              <Input
                ref={el => { this.inputRef[`${ref}-${month}`] = el; }}
                className={
                  `${this.props.supplier.level}
                  ${this.getLiveForecastInputColour(month, level)}
                  ${this.props.supplier.level === SUPPLIER_LEVEL ? '' : 'border-less-cell'}`
                }
                value={this.getForecastValue(month) === '0.00' ? '' : this.getForecastValue(month)}

                onChange={e => this.handleLiveForecastChange(month, e)}
                onFocus={e => this.handleLiveForecastFocus(`${ref}-${month}`, e)}
                onBlur={this.handleLiveForecastBlur}
                onPaste={e => this.handlePaste(e)}
                aria-label={`${`${supplierName} ${month}`} forecast`}
                disabled={this.props.supplier.level !== SUPPLIER_LEVEL}
              />
            )}
            {this.props.finalisedMonthsData[month].finalized === true && (
              <p>
                {this.getForecastValue(month) === '0.00' ? '' : this.getForecastValue(month)}
              </p>
            )}
          </Table.Cell>,

          <Table.Cell key={`actuals${ref}`} className="actuals-cell">
            { this.props.supplier[month]
              ? numberWithCommas(this.props.supplier[month].actual) : null }
          </Table.Cell>,

          <Table.Cell
            key={`vsForecast-${ref}`}
            className={`vs-forecast-cell ${this.getVsForecastCellColour(
              this.calculateVsForecast(this.props.supplier[month]).replace('%', '')
            )}`}
          >
            {this.calculateVsForecast(this.props.supplier[month], 1)}
          </Table.Cell>
        ]) }
        <Table.Cell className="yearly-total-cell">
          {this.fetchYearlyTotal(FORECASTS, level, saleshouseId, folioId, supplierId)}
        </Table.Cell>
        <Table.Cell className="yearly-total-cell">
          {this.fetchYearlyTotal(ACTUALS, level, saleshouseId, folioId, supplierId)}
        </Table.Cell>

        {(this.state.showErrorModal) && (
          <OMGModal
            isOpen={this.state.showErrorModal}
            message={this.state.errorMessage}
            heading={this.state.errorHeading}
            modalType="error"
            buttons={[
              { name: 'Continue', callback: this.toggleErrorModalDisplay }
            ]}
            toggleDisplay={this.toggleErrorModalDisplay}
          />
        )}
      </Table.Row>
    );
  }
}

PivotTableRow.propTypes = {
  supplier: PropTypes.shape({
    level: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    SaleshouseId: PropTypes.number.isRequired,
    FolioId: PropTypes.number,
    SupplierId: PropTypes.number
  }).isRequired,
  showChildren: PropTypes.bool.isRequired,
  getDeeperData: PropTypes.func.isRequired,
  changeTempForecastValue: PropTypes.func.isRequired,
  setInputRefToFocus: PropTypes.func.isRequired,
  inputRefToFocus: PropTypes.string,
  handleLiveForecastChange: PropTypes.func.isRequired,
  yearlySpend: PropTypes.shape({
    actuals: PropTypes.array,
    forecasts: PropTypes.array
  }).isRequired,
  finalisedMonthsData: PropTypes.objectOf(PropTypes.object).isRequired
};

PivotTableRow.defaultProps = {
  inputRefToFocus: null
};

export default connect(
  store => ({
    yearlySpend: store.totalSpend.yearlySpend,
    finalisedMonthsData: store.finalisedMonths
  }),
  { changeTempForecastValue }
)(PivotTableRow);

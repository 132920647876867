import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Header,
  Icon,
  Modal
} from 'semantic-ui-react';
import './OMGModal.css';

function OMGModal(props) {
  const modalIcon = {
    warning: 'warning sign',
    success: 'checkmark',
    error: 'warning'
  };

  return (
    <Modal
      size="small"
      className={`${props.modalType}Modal`}
      closeIcon
      open={props.isOpen}
      onClose={props.toggleDisplay}
    >
      <Header>
        <Icon name={modalIcon[props.modalType]} size="small" />
        {props.heading}
      </Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            {props.message}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {props.buttons.map(buttonObj => {
          const primary = !!buttonObj.primary;
          const basic = !primary;
          return [
            <Button
              key={buttonObj.name}
              basic={basic}
              onClick={buttonObj.callback}
              primary={primary}
            >
              {buttonObj.name}
            </Button>
          ];
        })}
      </Modal.Actions>
    </Modal>
  );
}
OMGModal.defaultProps = {
  buttons: []
};
OMGModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
  toggleDisplay: PropTypes.func.isRequired
};

export default OMGModal;
